<template>
  <div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      //
    }
  },
  created () {
    if (this.$route.params.id) {
      this.onClearEdited()
      this.onClearSelected()
      this.comodato = this.$route.params.id
      // alert(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters('ComodatosRecipientes', ['edited', 'loading']),
    retirado: {
      get () {
        return this.edited.retirado
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          retirado: value
        })
      }
    },
    comodato: {
      get () {
        return this.edited.comodato_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          comodato_id: value
        })
      }
    },
    recipiente: {
      get () {
        return this.edited.recipiente_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          recipiente_id: value
        })
      }
    },
  },
  watch: {
    selected (value) {
      if (value > 0) {
        this.recipiente = value
        this.onSubmit()
      }
    }
  },
  methods: {
    ...mapActions('ComodatosRecipientes', ['onSave', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('ComodatosRecipientes', ['setEdited']),
    ...mapActions({
      onFetchRecipientesSelect: 'RecipientesSelect/onFetch',
      onFetchComodatosRecipientes: 'ComodatosRecipientes/onFetch',
    }),
    onSubmit () {
      if (this.comodato && this.recipiente)
        this.onSave().then(response => {
          this.onFetchRecipientesSelect()
          this.onFetchComodatosRecipientes()
        })
      else alert('No se ha seleccionado un comodato')
    }
  }
}
</script>
