<template>
  <div>
    <v-sheet>
      <v-container
        v-show="!isSelectedCliente"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-check-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Datos del domicilio</v-list-item-title>
        </v-list-item>
        <v-row no-gutters>
          <v-col cols="12">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-show="isSelectedCliente"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="isSelectedCliente ? 'primary' : ''">mdi-check-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Datos del domicilio</v-list-item-title>
        </v-list-item>
        <div v-if="isSelectedDomicilio">
          <my-domicilio-editonlive
            v-model="domicilioId"
            :reset="reset"
            :resetValidation="resetValidation"
          ></my-domicilio-editonlive>
        </div>
        <div v-else>
          <my-domicilio-createonlive
            v-model="domicilioId"
            :reset="reset"
            :resetValidation="resetValidation"
          ></my-domicilio-createonlive>
        </div>
        <v-divider class="my-3"></v-divider>
        <my-domicilios-select
          v-model="domicilioId"
        ></my-domicilios-select>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DomiciliosSelect from '@/pages/domicilios/Select'
import DomicilioCreate from '@/pages/domicilios/Create'
import DomicilioEdit from '@/pages/domicilios/Edit'
export default {
  components: {
    'my-domicilio-editonlive': DomicilioEdit,
    'my-domicilio-createonlive': DomicilioCreate,
    'my-domicilios-select': DomiciliosSelect,
  },
  data () {
    return {
      panel: 1,
      reset: false,
      resetValidation: false,
      attrs: {
        // class: 'mb-6',
        boilerplate: true,
        // elevation: 2,
      },
    }
  },
  created () {
    // this.onDefaults()
  },
  computed: {
    /* Datos de la entidad comodatos */
    ...mapGetters('Domicilios', ['edited', 'filters']),
    ...mapGetters('Comodatos', { editedComodato: 'edited' }),
    comodatoClienteId () {
      return this.editedComodato.cliente_id
    },
    isSelectedCliente () {
      /* comodato store */
      return this.editedComodato.cliente_id > 0
    },
    isSelectedDomicilio () {
      /* comodato store */
      return this.editedComodato.domicilio_id > 0
    },
    clienteId: {
      get () {
        /* domicilio store */
        return this.edited.cliente_id
      },
      set (value) {
        /* domicilio store */
        this.setEdited({
          ...this.edited,
          cliente_id: value
        })
        this.setFilters({
          ...this.edited,
          cliente_id: value
        })
      }
    },
    domicilioId: {
      get () {
        /* comodato store */
        return this.editedComodato.domicilio_id
      },
      set (value) {
        /* comodato store */
        this.setEditedComodato({
          ...this.editedComodato,
          domicilio_id: value
        })
      }
    },
  },
  watch: {
    comodatoClienteId (value) {
      console.log('DomicilioForm.Comodato.cliente_id')
      console.log(value)
      /* domicilio */
      this.clienteId = value
    },
    domicilioId (value) {
      console.log('DomicilioForm.Comodato.domicilio_id')
      console.log(value)
      if (!value && this.comodatoClienteId) this.clienteId = this.comodatoClienteId
    },
  },
  methods: {
    ...mapActions('Domicilios', ['onFetch', 'onClearAll']),
    ...mapMutations('Domicilios', ['setEdited', 'setFilters']),
    ...mapMutations('Comodatos', { setEditedComodato: 'setEdited' }),
    onValidateDomicilio (value) {
      if (value) this.$emit('complete', value)
    },
  },
}
</script>
