<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :search="search"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      @click:row="onSelect"
    >
      <template v-slot:item.latitud="{ item }">
        <!--
        <div
          v-if="item.latitud && item.longitud"
        >
        </div>
        -->
        {{ item.latitud && item.longitud ? 'Si' : 'No' }}
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ searchText }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de domicilios para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: {
    clienteId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      search: '',
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.setFilters({
        ...this.filters,
        cliente_id: this.$route.params.id
      })
    }
    if (this.filters.cliente_id) this.onFetch()
  },
  computed: {
    ...mapGetters('Domicilios', ['all', 'headers', 'filters', 'loading', 'itemsLength', 'options']),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        if (this.filters.cliente_id) this.onFetch()
      }
    },
  },
  watch: {
    filters (value) {
      // if (value.cliente_id)
      //   this.onFetch()
    }
  },
  methods: {
    ...mapActions('Domicilios', ['onFetch']),
    ...mapMutations('Domicilios', ['setOptions', 'setEdited', 'setSelected', 'setFilters']),
     onSelect (value) {
      const aux = {
        id: value.id,
        descripcion: value.descripcion,
        observaciones: value.observaciones,
        latitud: value.latitud,
        longitud: value.longitud,
        cliente_id: value.cliente_id,
        posicion: value.posicion,
        zona_id: value.zona_id,
      }
      this.setEdited(aux)
      this.setSelected(aux)
    },
  }
}
</script>
