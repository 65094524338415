<template>
  <div>
    <v-sheet
      v-show="filters.comodato_id"
    >
      <v-container>
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="isSelected ? 'primary' : ''">mdi-check-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Datos de instalación</v-list-item-title>
        </v-list-item>
        <div v-if="isSelected">
          <my-comodato-instalacion-edit
          ></my-comodato-instalacion-edit>
        </div>
        <div v-else>
          <my-comodato-instalacion-create
          ></my-comodato-instalacion-create>
        </div>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DateInput from '@/components/DateInput'
import TipoInstalacionSelect from '@/pages/tipos_instalaciones/Select'
import ComodatoInstalacionCreate from '@/pages/comodatos_instalaciones/Create'
import ComodatoInstalacionEdit from '@/pages/comodatos_instalaciones/Edit'
export default {
  components: {
    'my-date-picker': DateInput,
    'my-tipo-instalacion-select': TipoInstalacionSelect,
    'my-comodato-instalacion-create': ComodatoInstalacionCreate,
    'my-comodato-instalacion-edit': ComodatoInstalacionEdit,
  },
  data () {
    return {
      reset: false,
      resetValidation: false,
    }
  },
  created () {
    // this.onDefaults()
  },
  computed: {
    ...mapGetters('ComodatosInstalaciones', ['edited', 'rules', 'filters']),
    ...mapGetters('Comodatos', ['comodatoId']),
    isSelected () {
      return this.edited.id > 0
    },
    realizado: {
      get () {
        return this.edited.realizado
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          realizado: value
        })
      }
    },
    orden: {
      get () {
        return this.edited.orden
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          orden: value
        })
      }
    },
    comodato: {
      get () {
        return this.edited.comodato_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          comodato_id: value
        })
        this.setFilters({
          ...this.filters,
          comodato_id: value
        })
      }
    },
    tipoInstalacion: {
      get () {
        return this.edited.tipo_instalacion_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          tipo_instalacion_id: value
        })
      }
    },
  },
  watch: {
    comodatoId (value) {
      // alert(value)
      // this.comodato = value
      // if (value) this.onFetch()
    },
    filters (value) {
      if (value.comodato_id) this.onFetch()
    }
  },
  methods: {
    ...mapActions('ComodatosInstalaciones', ['onFetch', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('ComodatosInstalaciones', ['setEdited', 'setFilters']),
  },
}
</script>
