import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import { getMessaging } from 'firebase/messaging/sw';

try {
  const firebaseApp = initializeApp({
    apiKey: "AIzaSyCjtv1g0OcNozLhShQFuUFFmG1IO_7OuWQ",
    authDomain: "sartinitest.firebaseapp.com",
    projectId: "sartinitest",
    storageBucket: "sartinitest.appspot.com",
    messagingSenderId: "598527743686",
    appId: "1:598527743686:web:fa39b6b78da4870c0e69c7",
    measurementId: "G-DV6LQZMJ5K"
  })
  
  const messaging = getMessaging(firebaseApp)

  getToken(messaging, {
      vapidKey: 'BEOwyIjYFnY7TjX69_HV8D-pn-1PnKzIXb4QmNpjnvsePomqyqo-sK3_2NREgOxj8sl5Wkcobo6qmh43bom5AWk'
    })
    .then((currentToken) => {
      if (currentToken) {
        // console.log(currentToken)
        // messaging.subscribeToTopic(currentToken, 'ordenes-updated')
        //   .then((response) => {
        //     console.log('Successfully subscribed to topic:', response);
        //   })
        //   .catch((error) => {
        //     console.log('Error subscribing to topic:', error);
        //   })
        // const registrationTokens = [
        //   'cqU7olX_MQNlj29XjpNnn1:APA91bGNZPkefipnwW7g_F0G0SQReB0oI9zd2OzMeItQPppJt5hySaYBNWVC_d3a-VWUBsr8tJm8lHomCO2Q8Ak7b6Rt0XuoBBc74MemdhrG2J_dAdc8hoOA_BPbIhfFSDhSg2_M0n-5'
        // ];
        // messaging
        //   .subscribeToTopic(registrationTokens, 'ordenes-updated')
        //   .then((response) => {
        //     console.log('Successfully subscribed to topic:', response);
        //   })
        //   .catch((error) => {
        //     console.log('Error subscribing to topic:', error);
        //   })
      } else {
        // console.log('No registration token available. Request permission to generate one.')
      }
    })
    // .catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    // })

  onMessage(messaging, (payload) => {
    alert(payload)
    // console.log('Message received. ', payload);
  })

} catch (error) {
  // console.log(error)
}