<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :show-select="false"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10,15,30]
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      :items-per-page="10"
      disable-sort
      @click:row="onSelect"
    >
      <template v-slot:item.firmado="{ item }">
        <div v-if="item.firmado">
          {{ item.firmado | moment('DD/MM/YYYY') }}
        </div>
      </template>
      <template v-slot:item.vencimiento="{ item }">
        <div v-if="item.vencimiento">
          {{ item.vencimiento | moment('DD/MM/YYYY') }}
        </div>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de comodatos para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TipoInstalacionSelect from '@/pages/tipos_instalaciones/Select'
import DateInput from '@/components/DateInput'
import ZonaSelect from '@/pages/zonas/Select'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Number,
  },
  data () {
    return {
      //
    }
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    // window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    // window.removeEventListener('keydown', this.handleKeyboard);
    this.all = []
  },
  created () {
    //
  },
  computed: {
    all: {
      get () {
        return this.$store.getters['Comodatos/all']
      },
      set (value) {
        this.$store.commit('Comodatos/setAll', value);
      }
    },
    filters: {
      get () {
        return this.$store.getters['Comodatos/filters']
      },
      set (value) {
        this.$store.commit('Comodatos/setFilters', value);
      }
    },
    itemsLength () {
      return this.$store.getters['Comodatos/itemsLength']
    },
    loading () {
      return this.$store.getters['Comodatos/loading']
    },
    headers () {
      return this.$store.getters['Comodatos/headers']
    },
    clienteId: {
      get () {
        return this.filters.cliente_id
      },
      set (value) {
        this.filters = {
          ...this.filters,
          cliente_id: value
        };
        this.onSubmitFetch()
      }
    },
    edited: {
      get () {
        return this.$store.getters['Comodatos/edited']
      },
      set (value) {
        this.$store.commit('Comodatos/setEdited', value)
      }
    },
    optionsLocal: {
      get () {
        return this.$store.getters['Comodatos/options']
      },
      set (value) {
        this.$store.commit('Comodatos/setOptions', value);
        this.onSubmitFetch()
      }
    },
  },
  watch: {
    selected (value) {
      this.clienteId = value
    }
  },
  methods: {
    onSelect (value) {
      const aux = Object.assign(this.edited, value)
      this.edited = aux
      this.$router.push({ name: 'comodatos-form', params: { id: this.edited.id } })
    },
    onSubmitFetch () {
      if (this.clienteId) {
        this.$store.dispatch('Comodatos/onFetch')
    }
    }
  }
}
</script>
