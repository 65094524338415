<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-alert
            v-if="updatable"
            border="left"
            colored-border
            color="warning"
            icon="mdi-alert"
          >
            Para guardar los cambios realizados, es necesario Actualizar
          </v-alert>
          <v-text-field
            v-model="numero"
            placeholder="Ingrese un numero"
            label="Número"
            :rules="rules.numero"
            prepend-icon="mdi-text"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="capacidad"
            placeholder="Ingrese una capacidad"
            label="Capacidad"
            :rules="rules.capacidad"
            prepend-icon="mdi-text"
            color="primary"
          ></v-text-field>
        </v-container>
        <v-footer>
          <v-btn
            :disabled="!updatable"
            color="primary"
            @click="onUpdateSubmit"
            :loading="loading"
          >
            Actualizar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="onDeleteSubmit"
            :loading="loading"
          >
            Eliminar
          </v-btn>
        </v-footer>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Number,
    comodatoId: Number
  },
  data () {
    return {
      valid: false,
    }
  },
  computed: {
    edited: {
      get () {
        return this.$store.getters['ComodatosTanques/edited']
      },
      set (value) {
        this.$store.commit('ComodatosTanques/setEdited', value)
      }
    },
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.edited = {
          ...this.edited,
          numero: value
        }
      }
    },
    capacidad: {
      get () {
        return this.edited.capacidad
      },
      set (value) {
        this.edited = {
          ...this.edited,
          capacidad: value
        }
      }
    },
    rules () {
      return this.$store.getters['ComodatosTanques/rules']
    },
    loading () {
      return this.$store.getters['ComodatosTanques/loading']
    },
    updatable () {
      return this.$store.getters['ComodatosTanques/updatable']
    },
  },
  watch: {
    //
  },
  methods: {
    onUpdate () {
      return this.$store.dispatch('ComodatosTanques/onUpdate')
    },
    onDelete () {
      return this.$store.dispatch('ComodatosTanques/onDelete')
    },
    onUpdateSubmit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.onUpdate().then(() => {
          this.$emit('change', this.edited.id)
          this.$emit('updated')
        })
      }
    },
    onDeleteSubmit () {
      this.onDelete().then(() => {
        this.$emit('deleted')
      })
    },
  }
}
</script>
