<template>
  <div>
    <v-sheet>
      <v-container>
        <v-subheader>Vigencia del comodato</v-subheader>
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="numero"
              label="Idetificación"
              :rules="rules.numero"
              prepend-icon="mdi-counter"
              color="primary"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <my-date-picker
              v-model="firmado"
              label="Fecha firmado"
              clearable
            ></my-date-picker>
          </v-col>
          <v-col cols="12" md="4">
            <my-date-picker
              v-model="vencimiento"
              label="Fecha vencimiento"
              clearable
            ></my-date-picker>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          @click="onRegistrar"
        >
          Registrar
        </v-btn>
        <v-btn
          color="primary"
          @click="onActualizar"
        >
          Actualizar
        </v-btn>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DateInput from '@/components/DateInput'
export default {
  data () {
    return {
      reset: false,
      resetValidation: false,
    }
  },
  created () {
    // this.onDefaults()
  },
  computed: {
    ...mapGetters('Comodatos', ['edited', 'rules']),
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          numero: value
        })
      }
    },
    firmado: {
      get () {
        return this.edited.firmado
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          firmado: value
        })
      }
    },
    vencimiento: {
      get () {
        return this.edited.vencimiento
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          vencimiento: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Comodatos', ['onUpdate', 'onSave', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Comodatos', ['setEdited']),
    onRegistrar (event) {
      event.preventDefault()

    },
    onActualizar (event) {
      event.preventDefault()
    },
  },
}
</script>
