<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      disable-sort
      @click:row="onClickRow"
    >
      <template v-slot:top>
        <v-container>
          <v-text-field
            v-model="searchText"
            placeholder="Escriba..."
            clearable
            @keydown.enter="onFetch"
            @click:clear="onClearFilters"
          >
            <template v-slot:prepend>
              <v-btn
                icon
                @click="onFetch"
                :disabled="!searchText || searchText.length == 0"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <template v-slot:append-outer>
              <v-btn
                color="primary"
                @click="onClickNuevo"
                v-show="$can('proveedores.store')"
              >
                <v-icon left>mdi-plus</v-icon>
                Nuevo
              </v-btn>
            </template>
          </v-text-field>
        </v-container>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de proveedores para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
    <my-proveedor-form
      v-model="selected"
    ></my-proveedor-form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ProveedorForm from '@/pages/proveedores/Form'
export default {
  components: {
    'my-proveedor-form': ProveedorForm
  },
  data () {
    return {
      selected: false,
    }
  },
  created () {
    // this.onFetch()
  },
  computed: {
    ...mapGetters('Proveedores', ['all', 'headers', 'filters', 'loading', 'itemsLength', 'options', 'edited']),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Proveedores', ['onFetch', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Proveedores', ['setEdited', 'setSelected', 'setFilters', 'setOptions']),
    onClearFilters () {
      this.searchText = null
      this.onFetch()
    },
    onClickRow (value) {
      const aux = Object.assign(this.edited, value)
      this.setEdited(aux)
      this.setSelected(aux)
      this.selected = true
    },
    onClickNuevo (event) {
      event.preventDefault()
      this.onClearEdited()
      this.onClearSelected()
      this.selected = true
    },
  }
}
</script>
