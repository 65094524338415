const URL = '/certificados_rehabilitantes';

const state = {
  all: [],
  selected: {
    id: null,
    emision: null,
    identificacion: null,
    codigo_construccion: null,
    aprobado_ph: null,
    vencimiento_ph: null,
    proveedor_id: null,
    adjunto: null
  },
  filters: {
    identificacion: null,
    codigo_construccion: null,
    emision: {
      from: null,
      to: null
    },
    proveedor_id: null
  },
  edited: {
    id: null,
    emision: null,
    identificacion: null,
    codigo_construccion: null,
    aprobado_ph: null,
    vencimiento_ph: null,
    proveedor_id: null,
    adjunto: null
  },
  default: {
    id: null,
    emision: null,
    identificacion: null,
    codigo_construccion: null,
    aprobado_ph: null,
    vencimiento_ph: null,
    proveedor_id: null,
    adjunto: null
  },
  loading: false,
  headers: [
    {
      text: 'Emisión',
      value: 'emision',
      align: 'center'
    },
    {
      text: 'Identificacion',
      value: 'identificacion',
      align: 'center'
    },
    {
      text: 'Código construcción',
      value: 'codigo_construccion',
      align: 'center'
    },
    {
      text: 'Fecha PH',
      value: 'aprobado_ph',
      align: 'center'
    },
    {
      text: 'Vencimiento PH',
      value: 'vencimiento_ph',
      align: 'center'
    },
    {
      text: 'Fabricante',
      value: 'fabricante.nombre',
      align: 'center'
    },
  ],
  rules: {
    emision: [
      v => !!v || 'Este campo es necesario',
    ],
    identificacion: [
      v => !!v || 'Este campo es necesario',
    ],
    codigo_construccion: [
      v => !!v || 'Este campo es necesario',
    ],
    proveedor_id: [
      v => !!v || 'Este campo es necesario',
      v => _.isInteger(v) || 'Debe existir un Proveedor seleccionado',
    ],
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  updatable (state) {
    return state.edited.id == state.selected.id && (
      state.edited.emision != state.selected.emision ||
      state.edited.identificacion != state.selected.identificacion ||
      state.edited.codigo_construccion != state.selected.codigo_construccion ||
      state.edited.aprobado_ph != state.selected.aprobado_ph ||
      state.edited.vencimiento_ph != state.selected.vencimiento_ph ||
      state.edited.proveedor_id != state.selected.proveedor_id ||
      state.edited.adjunto != state.selected.adjunto
    )
  },
  filters (state) {
    return state.filters
  },
  selected (state) {
    return state.selected
  },
  rules (state) {
    return state.rules
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  defaults (state) {
    return state.defaults
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onClearEdited ({ state, commit }) {
    commit('setEdited', state.default)
  },
  onClearSelected ({ state, commit }) {
    commit('setSelected', state.default)
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
          identificacion: state.filters.identificacion,
          codigo_construccion: state.filters.codigo_construccion,
          emision_from: state.filters.emision.from,
          emision_to: state.filters.emision.to,
          proveedor_id: state.filters.proveedor_id,
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/' + payload)
        .then(response => {
          commit('setEdited', response.data)
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            ...state.edited,
            id: response.data.data.id
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el certificado de rehabilitación', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el certificado de rehabilitación', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el certificado de rehabilitación', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el certificado de rehabilitación', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se ha borrado el certificado de rehabilitación', { root: true })
          commit('setEdited', state.defaults)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al borrar el certificado de rehabilitación', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
