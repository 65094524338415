<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-text-field
            v-model="numero"
            placeholder="Ingrese un numero"
            label="Número"
            :rules="rules.numero"
            prepend-icon="mdi-text"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="capacidad"
            placeholder="Ingrese una capacidad"
            label="Capacidad"
            :rules="rules.capacidad"
            prepend-icon="mdi-text"
            color="primary"
          ></v-text-field>
        </v-container>
        <v-footer>
          <v-btn
            @click="onSaveSubmit"
            color="primary"
            :loading="loading"
          >
            Registrar
          </v-btn>
        </v-footer>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Number,
    comodatoId: Number
  },
  data () {
    return {
      valid: false,
    }
  },
  computed: {
    edited: {
      get () {
        return this.$store.getters['ComodatosTanques/edited']
      },
      set (value) {
        this.$store.commit('ComodatosTanques/setEdited', value)
      }
    },
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.edited = {
          ...this.edited,
          numero: value
        }
      }
    },
    capacidad: {
      get () {
        return this.edited.capacidad
      },
      set (value) {
        this.edited = {
          ...this.edited,
          capacidad: value
        }
      }
    },
    rules () {
      return this.$store.getters['ComodatosTanques/rules']
    },
    loading () {
      return this.$store.getters['ComodatosTanques/loading']
    },
  },
  watch: {
    comodatoId (value) {
      // alert(value)
      // this.edited.comodato_id = value
    }
  },
  methods: {
    onSave () {
      return this.$store.dispatch('ComodatosTanques/onSave')
    },
    onSaveSubmit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(() => {
          this.$emit('change', this.edited.id)
          this.$emit('created')
        })
      }
    },
  }
}
</script>
