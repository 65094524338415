<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-subheader>Certificado de fabricante</v-subheader>
          <v-text-field
            v-model="identificacion"
            label="Número de certificado"
            :rules="rules.identificacion"
            prepend-icon="mdi-identifier"
            color="primary"
          ></v-text-field>
          <my-date-picker
            v-model="emision"
            label="Fecha de emisión"
            :rules="rules.emision"
          ></my-date-picker>
          <my-proveedor-select
            v-model="proveedor"
            :rules="rules.proveedor_id"
          ></my-proveedor-select>
          <v-text-field
            v-model="norma"
            placeholder="Ingrese una descripción"
            label="Norma de fabricación"
            :rules="rules.norma"
            prepend-icon="mdi-text-box"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="plano"
            placeholder="p. ej.: Z-8899 Rev. 9"
            label="Modelo aprobado según plano"
            :rules="rules.plano"
            prepend-icon="mdi-map-outline"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="matricula"
            placeholder="Ingrese un número de matrícula"
            label="Matrícula"
            :rules="rules.matricula"
            prepend-icon="mdi-pound"
            color="primary"
          ></v-text-field>
          <v-file-input
            accept="image/*"
            label="Documento Escaneado"
          ></v-file-input>
        </v-container>
        <v-footer>
          <v-btn
            color="primary"
            @click="onValidate"
          >
            Actualizar
          </v-btn>            
        </v-footer>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DateInput from '@/components/DateInput'
import ProveedorSelect from '@/pages/proveedores/Select'
import RecipientesAsociados from '@/pages/rehabilitaciones_recipientes/Datatable'
export default {
  components: {
    'my-date-picker': DateInput,
    'my-proveedor-select': ProveedorSelect,
    'my-recipientes-datatable': RecipientesAsociados
  },
  data () {
    return {
      valid: true,
    }
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Fabricaciones', {
      edited: 'edited',
      rules: 'rules',
      loading: 'loading'
    }),
    emision: {
      get () {
        return this.edited.emision
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          emision: value
        })
      }
    },
    matricula: {
      get () {
        return this.edited.matricula
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          matricula: value
        })
      }
    },
    identificacion: {
      get () {
        return this.edited.identificacion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          identificacion: value
        })
      }
    },
    norma: {
      get () {
        return this.edited.norma
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          norma: value
        })
      }
    },
    plano: {
      get () {
        return this.edited.plano
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          plano: value
        })
      }
    },
    proveedor: {
      get () {
        return this.edited.proveedor_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          proveedor_id: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Fabricaciones', ['onUpdate', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Fabricaciones', ['setEdited']),
    onValidate () {
      this.$refs.form.validate()
      if (this.valid) {
        this.onUpdate().then(() => {
          // this.$router.push({ name: 'recipientes-main' })
        })
      }
    },
  },
}
</script>
