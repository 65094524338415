<template>
  <div>
    <v-sheet>
      <v-container>
        <v-alert
          border="left"
          colored-border
          color="primary"
          icon="mdi-map-marker-check"
        >
          A continuación, se muestran los datos del domicilio seleccionado
        </v-alert>
        <v-alert
          v-if="updatable"
          border="left"
          colored-border
          color="warning"
          icon="mdi-alert"
        >
          Para guardar los cambios realizados, es necesario Actualizar
        </v-alert>
        <v-form
          ref="form"
          v-model="valid"      
        >
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="descripcion"
                placeholder="Descripcion"
                prepend-icon="mdi-text"
                :rules="rules.descripcion"
                label="Descripción"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="observaciones"
                placeholder="Ingrese observaciones"
                prepend-icon="mdi-text"
                :rules="rules.observaciones"
                label="Observaciones"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <my-zona-select
                v-model="zona"
                :rules="rules.zona_id"
              ></my-zona-select>
            </v-col>
              <!--
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="posicion"
                  prepend-icon="mdi-format-list-numbered"
                  :rules="rules.posicion"
                  label="Posición"
                  type="number"
                  v-bind="attrs"
                ></v-text-field>
              </v-col>
              -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="latitud"
                placeholder="Ingrese una latitud"
                prepend-icon="mdi-text"
                label="Latitud"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="longitud"
                placeholder="Ingrese una longitud"
                prepend-icon="mdi-text"
                label="Longitud"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-footer>
        <v-btn
          :disabled="!updatable"
          color="primary"
          @click="onSubmit"
          :loading="loading"
        >
          Actualizar
          <v-icon right>mdi-check</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="onSubmitClear"
        >
          Domicilio nuevo
          <v-icon right>mdi-map-marker-plus</v-icon>
        </v-btn>
      </v-footer>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ZonaSelect from '@/pages/zonas/Select'
export default {
  components: {
    'my-zona-select': ZonaSelect
  },
  model: {
    prop: 'domicilioId',
    event: 'change'
  },
  props: {
    domicilioId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      valid: false,
      submitButtonText: 'Guardar',
      backButtonText: 'Volver',
      attrs: {
        color: 'primary',
        readonly: false,
      },
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Domicilios', ['edited', 'rules', 'loading', 'updatable']),
    id () {
      return this.edited.id
    },
    descripcion: {
      get () {
        return this.edited.descripcion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          descripcion: value
        })
      }
    },
    latitud: {
      get () {
        return this.edited.latitud
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          latitud: value
        })
      }
    },
    longitud: {
      get () {
        return this.edited.longitud
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          longitud: value
        })
      }
    },
    observaciones: {
      get () {
        return this.edited.observaciones
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          observaciones: value
        })
      }
    },
    cliente: {
      get () {
        return this.edited.cliente_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cliente_id: value
        })
      }
    },
    zona: {
      get () {
        return this.edited.zona_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          zona_id: value
        })
      }
    },
    posicion: {
      get () {
        return this.edited.posicion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          posicion: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Domicilios', ['onFetch', 'onUpdate', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Domicilios', ['setEdited']),
    onSubmit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.onUpdate().then(() => {
          this.$emit('change', this.edited.id)
          this.onFetch()
        })
      }
    },
    onSubmitClear (event) {
      event.preventDefault()
      this.onClearEdited()
      this.onClearSelected()
      this.$refs.form.reset()
      this.setEdited({
        ...this.edited,
        cliente_id: this.clienteId
      })
      this.$emit('change', null)
    },
    handleKeyboard (event) {
      /* Nuevo Domicilio */
      if(event.key === "F3") {
        event.preventDefault()
        this.onSubmitClear()
      }
      else if(event.key === "F4") {
        event.preventDefault()
        if (this.updatable)
          this.onSubmit()
      }
    }
  },
}
</script>
