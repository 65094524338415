<template>
  <div>

    <v-select
      v-model="selectedLocal"
      :items="collection"
      hide-no-data
      item-text="nombre"
      item-value="id"
      :prepend-icon="!hideIcon ? 'mdi-terrain': ''"
      :rules="rules"
      :label="label"
      :placeholder="placeholder"
      :color="color"
      :readonly="readonly"
      :clearable="clearable"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },

  props: {
    selected: Number,
    rules: Array,
    readonly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      placeholder: 'Seleccione una zona',
      label: 'Zona',
    }
  },

  created () {
    // this.setItemsPerPage('10000')
    this.onFetch()
  },

  mounted () {
  },

  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },

      set (value) {
        this.$emit('change', value)
      }
    },
    ...mapGetters({
      collection: 'Zonas/all',
    }),
  },

  methods: {
    ...mapActions({
      onFetch: 'Zonas/onFetchAll',
    }),
    // ...mapMutations({
    //   setItemsPerPage: 'Zonas/setItemsPerPage',
    // }),
  },
}
</script>
