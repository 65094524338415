const URL = '/comodatos_recipientes';

const state = {
  all: [],
  recipiente: {
    numero: null,
    capacidad: null,
    volumen: null,
    aprobado_ph: null,
    vencimiento_ph: null,
    presion_disenio: null,
    presion_ph: null,
    recipiente_tipo_id: null,
    certificado_fabricante_id: null,
  },
  selected: {
    id: null,
    retirado: null,
    comodato_id: null,
    recipiente_id: null,
  },
  edited: {
    id: null,
    retirado: null,
    comodato_id: null,
    recipiente_id: null,
  },
  default: {
    id: null,
    retirado: null,
    comodato_id: null,
    recipiente_id: null,
  },
  filters: {
    searchText: null,
    comodato_id: null,
    recipiente_id: null,
    recipiente_tipo_id: null,
    retirado: {
      from: null,
      to: null
    },
  },
  loading: false,
  headers: [
    {
      text: 'Matricula',
      value: 'recipiente.numero',
      align: 'center'
    },
    {
      text: 'Fabricante',
      value: 'recipiente.fabricante',
      align: 'center'
    },
    {
      text: 'Tipo',
      value: 'recipiente.tipo',
      align: 'center'
    },
    {
      text: 'Capacidad (m3)',
      value: 'recipiente.capacidad',
      align: 'center'
    },
    {
      text: 'Volumen (Lts)',
      value: 'recipiente.volumen',
      align: 'center'
    },
    {
      text: 'Presión diseño (Kg/cm2)',
      value: 'recipiente.presion_disenio',
      align: 'center'
    },
    {
      text: 'Presión PH (Kg/cm2)',
      value: 'recipiente.presion_ph',
      align: 'center'
    },
    {
      text: 'Fecha PH',
      value: 'recipiente.aprobado_ph',
      align: 'center'
    },
    {
      text: 'Vencimiento PH',
      value: 'recipiente.vencimiento_ph',
      align: 'center'
    },
    {
      text: 'Fecha Retirado',
      value: 'retirado',
      align: 'center'
    },
  ],
  validations: {
    retirado: [
      // v => !!v || 'Este campo es necesario',
    ],
    recipiente_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir un recipiente seleccionado',
    ],
    comodato_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir un comodato seleccionado',
    ],
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  filters (state) {
    return state.filters
  },
  selected (state) {
    return state.selected
  },
  isSelected (state) {
    return state.edited.id > 0
  },
  rules (state) {
    return state.validations
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
  // Getter para verificar si los atributos de edited cumplen con las validaciones
  isValidEdited(state) {
    const edited = state.edited;
    const validations = state.validations;
    
    // Iterar sobre las claves de las validaciones
    for (const key in validations) {
      // Obtener las validaciones para el atributo actual
      const rules = validations[key];
      
      // Iterar sobre las reglas de validación para el atributo actual
      for (const rule of rules) {
        // Si alguna regla de validación no se cumple, retorna falso
        if (!rule(edited[key])) {
          return false;
        }
      }
    }
    
    // Si todas las validaciones pasan, retorna verdadero
    return true;
  }
}

// ACTIONS
const actions = {
  onClearEdited ({ commit }) {
    commit('setEdited', {
      id: null,
      retirado: null,
      comodato_id: null,
      recipiente_id: null,
    })
  },
  onClearSelected ({ commit }) {
    commit('setEdited', {
      id: null,
      retirado: null,
      comodato_id: null,
      recipiente_id: null,
    })
  },
  onClearFilters ({ commit }) {
    commit('setFilters', {
      searchText: null,
      comodato_id: null,
      recipiente_id: null,
      recipiente_tipo_id: null,
      retirado: {
        from: null,
        to: null
      },
    })
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          comodato_id: state.filters.comodato_id,
          recipiente_id: state.filters.recipiente_id,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            id: response.data.data.id,
            retirado: response.data.data.retirado,
            comodato_id: response.data.data.comodato_id,
            recipiente_id: response.data.data.recipiente_id,
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el comodato', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el comodato', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se ha borrado el comodato', { root: true })
          dispatch('onClearEdited')
          dispatch('onClearSelected')
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al borrar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onAdd ({ state, commit }, payload) {
    // recipiente: {
    //   numero: null,
    //   capacidad: null,
    //   volumen: null,
    //   aprobado_ph: null,
    //   vencimiento_ph: null,
    //   presion_disenio: null,
    //   presion_ph: null,
    //   certificado_fabricante_id: null,
    // },
    let allAux = state.all
    allAux.push(payload)
    commit('setAll', allAux)
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setEditedRecipiente (state, payload) {
    state.edited.recipiente = {
      numero: payload.numero,
      fabricante: payload.fabricante,
      capacidad: payload.capacidad,
      presion_disenio: payload.presion_disenio,
      presion_ph: payload.presion_ph,
      aprobado_ph: payload.aprobado_ph,
      vencimiento_ph: payload.vencimiento_ph,
    }
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
