const URL = '/comodatos';

const state = {
  all: [],
  selected: {
    id: null,
    numero: null,
    firmado: null,
    vencimiento: null,
    cliente_id: null,
    domicilio_id: null,
  },
  paneles: {
    cliente: false,
    domicilio: false,
    instalacion: true,
    recipientes: true,
  },
  filters: {
    numero: null,
    firmado: {
      from: null,
      to: null
    },
    vencimiento: {
      from: null,
      to: null
    },
    cliente_id: null,
    domicilio_id: null,
  },
  edited: {
    id: null,
    numero: null,
    firmado: null,
    vencimiento: null,
    cliente_id: null,
    domicilio_id: null,
  },
  default: {
    id: null,
    numero: null,
    firmado: null,
    vencimiento: null,
    cliente_id: null,
    domicilio_id: null,
  },
  loading: false,
  headers: [
    // cliente, domicilio, orden instalacion, comodato firmado, carpeta tecnica firmada, vencimiento, acciones
    {
      text: 'Tipo instalación',
      value: 'tipo_instalacion.descripcion',
      align: 'center'
    },
    {
      text: 'Domicilio',
      value: 'domicilio.descripcion',
      align: 'center'
    },
    {
      text: 'Fecha Firmado',
      value: 'firmado',
      align: 'center'
    },
    {
      text: 'Fecha Vencimiento',
      value: 'vencimiento',
      align: 'center'
    },
    {
      text: 'Recipientes',
      value: 'recipientes',
      align: 'center'
    },
  ],
  rules: {
    numero: [
      v => !!v || 'Este campo es necesario',
    ],
    cliente_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir un cliente asociado',
    ],
    domicilio_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir un domicilio asociado',
    ],
    firmado: [
      // v => !!v || 'Este campo es necesario',
    ],
    vencimiento: [
      // v => !!v || 'Este campo es necesario',
    ],
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  paneles (state) {
    return state.paneles
  },
  comodatoId (state) {
    return state.edited.id
  },
  filters (state) {
    return state.filters
  },
  rules (state) {
    return state.rules
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
  updatable (state) {
    return state.edited.id == state.selected.id && (
      state.edited.numero != state.selected.numero ||
      state.edited.firmado != state.selected.firmado ||
      state.edited.vencimiento != state.selected.vencimiento ||
      (state.edited.cliente_id && state.edited.cliente_id != state.selected.cliente_id) ||
      (state.edited.domicilio_id && state.edited.domicilio_id != state.selected.domicilio_id)
    )
  },
}

// ACTIONS
const actions = {
  onClearAll ({ commit }) {
    commit('setAll', [])
  },
  onClearEdited ({ state, commit}) {
    commit('setEdited', state.default)
  },
  onClearSelected ({ state, commit}) {
    commit('setSelected', state.default)
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          numero: state.filters.numero,
          firmado_from: state.filters.firmado.from,
          firmado_to: state.filters.firmado.to,
          vencimiento_from: state.filters.vencimiento.from,
          vencimiento_to: state.filters.vencimiento.to,
          cliente_id: state.filters.cliente_id,
          domicilio_id: state.filters.domicilio_id,
          tipo_instalacion_id: state.filters.tipo_instalacion_id,
          zona_id: state.filters.zona_id,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/' + payload)
        .then(response => {
          const aux = {
            id: response.data.id,
            numero: response.data.numero,
            firmado: response.data.firmado,
            vencimiento: response.data.vencimiento,
            domicilio_id: response.data.domicilio_id,
            cliente_id: response.data.cliente_id,
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            id: response.data.data.id,
            numero: response.data.data.numero,
            firmado: response.data.data.firmado,
            vencimiento: response.data.data.vencimiento,
            domicilio_id: response.data.data.domicilio_id,
            cliente_id: response.data.data.cliente_id,
            domicilio: response.data.data.domicilio
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el comodato', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el comodato', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se ha borrado el comodato', { root: true })
          commit('setEdited', state.default)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al borrar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setPaneles (state, payload) {
    state.paneles = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
