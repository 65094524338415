<template>
  <div>
    <v-sheet>
      <v-container>
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="isSelected ? 'primary' : ''">mdi-check-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Datos del cliente</v-list-item-title>
        </v-list-item>
        <div v-if="isSelected">
          <my-cliente-edit
            v-model="clienteId"
            :reset="reset"
            :resetValidation="resetValidation"
            mostrar-cliente-nuevo
          ></my-cliente-edit>
        </div>
        <div v-else>
          <my-cliente-create
            v-model="clienteId"
            :reset="reset"
            :resetValidation="resetValidation"
          ></my-cliente-create>
        </div>
        <v-divider class="ma-3"></v-divider>
        <my-cliente-select
          v-model="clienteId"
        ></my-cliente-select>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ClienteSelect from '@/pages/clientes/Select'
import ClienteCreateOnLive from '@/pages/clientes/Create'
import ClienteEditOnLive from '@/pages/clientes/Edit'
export default {
  components: {
    'my-cliente-create': ClienteCreateOnLive,
    'my-cliente-edit': ClienteEditOnLive,
    'my-cliente-select': ClienteSelect,
  },
  data () {
    return {
      reset: false,
      resetValidation: false,
    }
  },
  created () {
    // this.onDefaults()
  },
  computed: {
    ...mapGetters('Comodatos', ['edited']),
    isSelected () {
      return this.clienteId > 0
    },
    clienteId: {
      get () {
        /* comodato */
        return this.edited.cliente_id
      },
      set (value) {
        /* comodato */
        this.setEdited({
          ...this.edited,
          cliente_id: value
        })
      }
    },
  },
  watch: {
    clienteId (value) {
      console.log('ClienteForm.Comodato.cliente_id')
      console.log(value)
    }
  },
  methods: {
    ...mapMutations('Comodatos', ['setEdited']),
    onClearClienteId () {
      this.clienteId = null
    },
  },
}
</script>
