<template>
  <div>
    <v-sheet>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          dark
          color="error"
          @click="onSubmit"
          :loading="loading"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-delete</v-icon>
          <div class="hidden-xs-only">{{ submitButtonText }}</div>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              :value="edited.orden"
              label="Orden"
              prepend-icon="mdi-label"
              color="error"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :value="edited.nombre"
              label="Nombre"
              prepend-icon="mdi-map-marker"
              color="error"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :value="edited.observaciones"
              label="Observaciones"
              color="error"
              prepend-icon="mdi-counter"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :value="edited.zona_id"
              label="Zona"
              color="error"
              prepend-icon="mdi-counter"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    //
  },
  data () {
    return {
      redirect: 'zonas-datatable',
      title: '¿Está seguro de borrar este registro?',
      submitButtonText: 'Borrar',
      backButtonText: 'Volver',
    }
  },
  created () {
    this.onDefaults()
    if (this.$route.params.id)
      this.onFind(this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      edited: 'Zonas/edited',
      loading: 'Zonas/loading',
    }),
  },
  methods: {
    ...mapActions({
      onDelete: 'Zonas/onDelete',
      onFind: 'Zonas/onFind',
      onDefaults: 'Zonas/onDefaults',
    }),
    onCancel () {
      this.$router.push({ name: this.redirect })
    },
    onSubmit () {
      this.onDelete().then(response => {
        this.$router.push({ name: this.redirect })
      })
    },
  },
}
</script>
