<template>
  <div>
    <v-row>
      <v-col cols="4">
        <div v-show="isSelected">
          <my-clientes-edit
            subheader="Datos del cliente"
          ></my-clientes-edit>
        </div>
        <div v-show="!isSelected">
          <my-clientes-create
            subheader="Datos del cliente"
          ></my-clientes-create>
        </div>
      </v-col>
      <v-col cols="8">
        <v-sheet>
          <v-container>
            <v-subheader>Domicilios asociados al cliente</v-subheader>
            <div v-show="isSelected">
              <my-domicilios-datatable
                :cliente-id="clienteId"
              ></my-domicilios-datatable>
            </div>
            <div v-show="!isSelected">
              <v-skeleton-loader
                v-bind="attrs"
                type="table"
              ></v-skeleton-loader>
            </div>
          </v-container>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet>
          <v-container
            v-if="clienteId && domicilioEdited && domicilioEdited.id"
          >
            <my-domicilio-edit></my-domicilio-edit>
          </v-container>
          <v-container
            v-else-if="clienteId"
          >
            <my-domicilio-create
              :cliente-id="clienteId"
            ></my-domicilio-create>
          </v-container>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet>
          <v-container>
            <v-subheader>Comodatos del cliente</v-subheader>
            <div v-show="isSelected">
              <my-comodatos-datatable
                v-model="clienteId"
              ></my-comodatos-datatable>
            </div>
            <div v-show="!isSelected">
              <v-skeleton-loader
                v-bind="attrs"
                type="table"
              ></v-skeleton-loader>
            </div>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DomicilioCreate from '@/pages/domicilios/Create'
import DomicilioEdit from '@/pages/domicilios/Edit'
import DomiciliosDatatable from '@/pages/domicilios/Datatable'
import ClientesCreate from '@/pages/clientes/Create'
import ClientesEdit from '@/pages/clientes/Edit'
import ComodatosDatatable from '@/pages/comodatos/Datatable'

export default {
  components: {
    'my-domicilio-create': DomicilioCreate,
    'my-domicilio-edit': DomicilioEdit,
    'my-domicilios-datatable': DomiciliosDatatable,
    'my-clientes-create': ClientesCreate,
    'my-clientes-edit': ClientesEdit,
    'my-comodatos-datatable': ComodatosDatatable,
  },
  data () {
    return {
      valid: true,
      attrs: {
        // class: 'mb-6',
        // height: '500px',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  beforeDestroy() {
    /* clientes store */
    this.$store.dispatch('Clientes/onClearEdited');
    this.$store.dispatch('Clientes/onClearSelected');
    /* domicilios store */
    this.$store.dispatch('Domicilios/onClearEdited');
    this.$store.dispatch('Domicilios/onClearSelected');
    /* comodatos store */
    this.$store.dispatch('Comodatos/onClearAll');
  },
  created () {
    if (this.$route.params.id) {
      this.$store.dispatch('Clientes/onFind', this.$route.params.id);
    }
  },
  computed: {
    edited: {
      get () {
        return this.$store.getters['Clientes/edited']
      },
      set (value) {
        this.$store.commit('Clientes/edited', value);
      }
    },
    domicilioEdited: {
      get () {
        return this.$store.getters['Domicilios/edited']
      },
      set (value) {
        this.$store.commit('Domicilios/edited', value);
      }
    },
    isSelected () {
      return this.edited.id > 0
    },
    clienteId () {
      return this.edited.id
    }
  },
  watch: {
    //
  },
  methods: {
    //
  },
}
</script>
