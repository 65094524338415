import Vue from 'vue'
import Vuetify from 'vuetify'
// import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'


// import 'material-design-icons-iconfont/dist/material-design-icons.css';
import colors from 'vuetify/es5/util/colors'
// import colors from 'vuetify/lib/util/colors'
// import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

const opts = {
    iconfont: 'md',
    theme: {
        themes: {
            light: {
                toolbar: colors.blue.darken4,
                toolbarList: colors.cyan.darken2,
                listSelected: colors.black,
                primary: colors.blue.darken4,
                searchPanel: colors.grey.lighten1,
                secondary: colors.amber.base,
                accent: colors.indigo.base,
                background: colors.blueGrey.darken3,
                sidebar: colors.blueGrey.darken4,
                list: colors.teal.darken1,
                show: colors.blue.lighten2,
                create: colors.green.darken1,
                edit: colors.orange.darken3,
                delete: colors.red.darken4,
                searcher: colors.cyan.darken1,
                subtotal: colors.yellow.lighten1,
            },
            dark: {
                toolbar: colors.blue.darken4,
                toolbarList: colors.cyan.darken2,
                listSelected: colors.black,
                searchPanel: colors.grey.lighten1,
                primary: colors.blue.darken4,
                secondary: colors.amber.base,
                accent: colors.indigo.base,
                background: colors.blueGrey.darken4,
                sidebar: colors.blueGrey.darken4,
                list: colors.teal.darken1,
                show: colors.blue.lighten2,
                create: colors.green.darken1,
                edit: colors.orange.darken3,
                delete: colors.red.darken4,
                searcher: colors.cyan.darken1,
                subtotal: colors.yellow.lighten1,
            },
        },
        // themes: {
        //     light: {
        //         toolbar: colors.grey.darken1,
        //         toolbarList: colors.cyan.darken2,
        //         listSelected: colors.black,
        //         primary: colors.grey.darken1,
        //         secondary: colors.amber.base,
        //         accent: colors.indigo.base,
        //         background: colors.blueGrey.darken3,
        //         sidebar: colors.blueGrey.darken4,
        //         list: colors.teal.darken1,
        //         show: colors.blue.lighten2,
        //         create: colors.green.darken1,
        //         edit: colors.orange.darken3,
        //         delete: colors.red.darken4,
        //         searcher: colors.cyan.darken1,
        //         subtotal: colors.yellow.lighten1,
        //     },
        //     dark: {
        //         // toolbar: colors.cyan.darken4,
        //         toolbar: colors.grey.darken1,
        //         toolbarList: colors.cyan.darken2,
        //         listSelected: colors.black,
        //         primary: colors.grey.darken1,
        //         secondary: colors.amber.base,
        //         accent: colors.indigo.base,
        //         background: colors.blueGrey.darken4,
        //         sidebar: colors.blueGrey.darken4,
        //         list: colors.teal.darken1,
        //         show: colors.blue.lighten2,
        //         create: colors.green.darken1,
        //         edit: colors.orange.darken3,
        //         delete: colors.red.darken4,
        //         searcher: colors.cyan.darken1,
        //         subtotal: colors.yellow.lighten1,
        //     },
        // },
    },
    // lang: {
    //     locales: { es },
    //     current: 'es',
    // },
}
export default new Vuetify(opts)