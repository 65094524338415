window._ = require('lodash');
import Axios from 'axios'
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = Axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.defaults.baseURL = process.env.MIX_API_URL;

const authInterceptor = config => {
  config.headers['Authorization'] = window.sessionStorage.getItem('token_type') + ' ' + window.sessionStorage.getItem('access_token')
  return config;
}

const successInterceptor = response => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}

const errorInterceptor = error => {
  // check if it's a server error
  if (!error.response) {
    return Promise.reject(error);
  }

  // all the other error responses
  switch (error.response.status) {

    case 401:
      // authentication error, logout the user
      console.error(error.response.status, error.message);
      window.sessionStorage.removeItem('token_type')
      window.sessionStorage.removeItem('access_token')
      window.sessionStorage.removeItem('permissions')
      window.location.href = '/unauthorized'
      break;

    default:
      break;
  }

  return Promise.reject(error);
}

window.axios.interceptors.request.use(authInterceptor);
window.axios.interceptors.response.use(successInterceptor, errorInterceptor);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
