<template>
  <div>
    <v-expansion-panels v-model="panelOpen">
      <v-expansion-panel>
        <v-expansion-panel-header color="searchPanel">Recipientes nuevos</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-container>
            
              <v-subheader>Características de los recipientes nuevos </v-subheader>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="capacidad"
                    type="number"
                    label="Capacidad"
                    :rules="rules.capacidad"
                    color="primary"
                  >
                    <template v-slot:prepend>
                      <div>m<sup>3</sup></div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="presionDisenio"
                    type="number"
                    placeholder="Ingrese presión de diseño en kg/cm2"
                    label="Presión diseño"
                    :rules="rules.presion_disenio"
                    prepend-icon="mdi-decimal"
                    color="primary"
                  >
                    <template v-slot:prepend>
                      <div><sup>kg</sup>/<sub>cm<sup>2</sup></sub></div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="presionPh"
                    type="number"
                    placeholder="Ingrese presión de prueba hidrálica"
                    label="Presión PH"
                    :rules="rules.presion_ph"
                    prepend-icon="mdi-decimal"
                    color="primary"
                  >
                    <template v-slot:prepend>
                      <div><sup>kg</sup>/<sub>cm<sup>2</sup></sub></div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="volumen"
                    type="number"
                    label="Volumen de agua"
                    :rules="rules.volumen"
                    color="primary"
                  >
                    <template v-slot:prepend>
                      <div>lts</div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <my-periodo-input
                    v-model="aprobadoPh"
                    :resetForm="resetForm"
                    label="Fecha de PH"
                    :rules="rules.aprobado_ph"
                  ></my-periodo-input>
                </v-col>
                <v-col cols="12" md="4">
                  <my-periodo-input
                    v-model="vencimientoPh"
                    :resetForm="resetForm"
                    label="Fecha de vencimiento PH"
                    :rules="rules.vencimiento_ph"
                  ></my-periodo-input>
                </v-col>
                <v-col cols="12" md="4">
                  <my-recipiente-tipo-select
                    v-model="tipo"
                    :rules="rules.recipiente_tipo_id"
                  ></my-recipiente-tipo-select>
                </v-col>
                <v-col cols="12" md="4">
                  <my-fabricante-select
                    v-model="fabricante"
                    :rules="rules.fabricante_id"
                  ></my-fabricante-select>
                </v-col>
              </v-row>
              <v-divider inset></v-divider>
              <v-subheader>Numeración de los recipientes</v-subheader>
              <v-alert
                border="left"
                colored-border
                color="primary"
                icon="mdi-information"
              >
                Para multiples registros, completar campos Número desde y Número hasta. Para un solo registro, completar solo el campo Número desde
              </v-alert>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="numero"
                    type="number"
                    prepend-icon="mdi-ray-start-arrow"
                    label="Número desde"
                    :rules="rules.numero"
                    color="primary"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="numeroHasta"
                    type="number"
                    prepend-icon="mdi-ray-start-end"
                    label="Número hasta"
                    :rules="rulesNumeroHasta"
                    color="primary"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-footer>
              <v-btn
                color="primary"
                @click="onSubmitRegistrar"
              >
                Registrar
              </v-btn>
              <v-btn
                color="default"
                @click="onSubmitClear"
              >
                Limpiar formulario
              </v-btn>          
            </v-footer>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import PeriodoInput from '@/components/PeriodoInput'
import RecipienteTipoSelect from '@/pages/recipientes_tipos/Select'
import FabricanteSelect from '@/pages/fabricantes/Select'
export default {
  components: {
    'my-periodo-input': PeriodoInput,
    'my-recipiente-tipo-select': RecipienteTipoSelect,
    'my-fabricante-select': FabricanteSelect
  },
  props: {
    certificadoFabricanteId: {
      type: Number,
      default: null
    },
    resetForm: {
      type: Boolean,
      default: null
    },
  },
  data () {
    return {
      valid: true,      
      panelOpen: [1], // Inicialmente cerrado
      rulesNumeroHasta: [
        // v => !isNaN(parseFloat(v)) && isFinite(v) || 'Debe ser un valor numérico',
        // v => parseInt(v) >= parseInt(this.numero) || 'Número hasta debe ser mayor o igual que número desde'
        v => v === null || v === '' || ( !isNaN(parseFloat(v)) && isFinite(v) && parseInt(v) >= parseInt(this.numero) ) || 'Debe ser un valor numérico mayor o igual a número desde'
      ]
    }
  },
  created () {
    this.onClearEdited()
  },
  computed: {
    ...mapGetters('Recipientes', ['edited', 'rules', 'loading']),
    isSelected () {
      return this.edited.id != null && this.edited.id != undefined
    },
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          numero: value
        })
      }
    },
    numeroHasta: {
      get () {
        return this.edited.numero_hasta
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          numero_hasta: value
        })
      }
    },
    capacidad: {
      get () {
        return this.edited.capacidad
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          capacidad: value
        })
      }
    },
    volumen: {
      get () {
        return this.edited.volumen
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          volumen: value
        })

      }
    },
    aprobadoPh: {
      get () {
        return this.edited.aprobado_ph
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          aprobado_ph: value
        })
      }
    },
    vencimientoPh: {
      get () {
        return this.edited.vencimiento_ph
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          vencimiento_ph: value
        })
      }
    },
    presionDisenio: {
      get () {
        return this.edited.presion_disenio
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          presion_disenio: value
        })
      }
    },
    presionPh: {
      get () {
        return this.edited.presion_ph
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          presion_ph: value
        })
      }
    },
    tipo: {
      get () {
        return this.edited.recipiente_tipo_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          recipiente_tipo_id: value
        })
      }
    },
    fabricante: {
      get () {
        return this.edited.fabricante_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          fabricante_id: value
        })
      }
    },
    certificadoFabricante: {
      get () {
        return this.edited.certificado_fabricante_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          certificado_fabricante_id: value
        })
      }
    },
  },
  watch: {
    certificadoFabricanteId (value) {
      this.certificadoFabricante = value
    },
    resetForm (value) {
      // if (value) this.$refs.form.reset()
      this.numero = null
      this.numeroHasta = null
    },
  },
  methods: {
    ...mapActions('Recipientes', ['onSave', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Recipientes', ['setEdited']),
    onSubmitRegistrar (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(() => {
          this.$emit('success')
          // this.$router.push({ name: 'recipientes-main' })
        })
      }
    },
    onSubmitClear (event) {
      event.preventDefault()
      this.$refs.form.reset()
    }
  },
}
</script>
