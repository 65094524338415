<template>
  <div>
    <v-file-input
      v-model="selectedLocal"
      :accept="accept"
      :label="label"
      ref="adjuntoInput"
    ></v-file-input>
    
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: String,
    label: {
      type: String,
      default: 'Documento adjunto'
    },
    accept: {
      type: String,
      default: '*'
    },
  },
  data () {
    return {
      dateErrorMessages: [],
      valid: false,
      selectedLocal: null,
    }
  },
  watch: {
    selectedLocal(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.uploadFile(newVal); // Llama a la función uploadFile() cuando se selecciona un nuevo archivo
      }
    },
  },
  /*
  watch: {
    resetForm (value) {
      if (value) this.$refs.form.reset()
    },
  },
  */
  methods: {
    async uploadFile() {
      const formData = new FormData();
      formData.append('adjunto', this.selectedLocal);

      try {
        await this.onUpload(formData)
        // Limpia el campo después de subir el archivo
          .then(response => {
            this.$emit('uploaded', response.ruta)
          });
        this.selectedLocal = null;
      } catch (error) {
        console.error('Error al cargar el archivo:', error);
      }
    },
    ...mapActions('Adjuntos', ['onUpload']),
    async descargarAdjunto() {
      try {
        const response = await axios.get(`/ruta/al/archivo/${this.adjunto}`, {
          responseType: 'blob', // Especificamos que esperamos una respuesta de tipo blob (archivo binario)
        });
        // Crear un objeto URL para el blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // Crear un enlace para iniciar la descarga del archivo
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.adjunto); // Establecer el nombre de descarga del archivo
        // Simular un clic en el enlace para iniciar la descarga
        document.body.appendChild(link);
        link.click();
        // Limpiar el objeto URL después de la descarga
        window.URL.revokeObjectURL(url);
        // Eliminar el enlace creado
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al descargar el archivo:', error);
      }
    },
  },
  /*
  methods: {
    ...mapActions('Adjuntos', ['onUpload']),
    onSubmitUpload(event) {
      event.preventDefault();
      // Preparar los datos para enviar al servidor
      const formData = new FormData();
      // Agregar el archivo adjunto al objeto FormData
      formData.append('adjunto', this.adjunto);

      // Enviar los datos al servidor
      this.onUpload()
        .then(response => {
          // this.$router.push({ name: 'recipientes-main' })
        })
        .catch(err => {
          console.error('Error al enviar el archivo:', err);
        })
    }
  },
  */
}
</script>
