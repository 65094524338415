var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.all,"footer-props":{
      itemsPerPageText: 'Filas por página',
      itemsPerPageOptions: [10,30,90]
    },"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemsLength,"items-per-page":15,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
    var item = ref.item;
return [(item.id)?_c('div',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){}}},[_vm._v("mdi-delete")])],1)],1):_vm._e()]}},{key:"item.retirado",fn:function(ref){
    var item = ref.item;
return [(item.retirado)?_c('div',[_vm._v("\n        "+_vm._s(_vm._f("moment")(item.retirado,'MM/YYYY'))+"\n      ")]):_vm._e()]}},{key:"item.recipiente.fabricante",fn:function(ref){
    var item = ref.item;
return [(item.recipiente.fabricante)?_c('div',[_vm._v("\n        "+_vm._s(item.recipiente.fabricante.nombre)+"\n      ")]):_vm._e()]}},{key:"item.recipiente.tipo",fn:function(ref){
    var item = ref.item;
return [(item.recipiente.tipo)?_c('div',[_vm._v("\n        "+_vm._s(item.recipiente.tipo.abreviatura)+"\n      ")]):_vm._e()]}},{key:"item.recipiente.vencimiento_ph",fn:function(ref){
    var item = ref.item;
return [(item.recipiente.vencimiento_ph)?_c('div',[_vm._v("\n        "+_vm._s(_vm._f("moment")(item.recipiente.vencimiento_ph,'MM/YYYY'))+"\n      ")]):_vm._e()]}},{key:"item.recipiente.aprobado_ph",fn:function(ref){
    var item = ref.item;
return [(item.recipiente.aprobado_ph)?_c('div',[_vm._v("\n        "+_vm._s(_vm._f("moment")(item.recipiente.aprobado_ph,'MM/YYYY'))+"\n      ")]):_vm._e()]}},{key:"item.recipiente.fabricacion",fn:function(ref){
    var item = ref.item;
return [(item.recipiente.fabricacion)?_c('div',[_c('v-chip',{on:{"click":function($event){}}},[_vm._v(_vm._s(item.recipiente.fabricacion))])],1):_vm._e()]}},{key:"item.rehabilitacion",fn:function(ref){
    var item = ref.item;
return [(item.rehabilitacion)?_c('div',[_c('v-chip',{on:{"click":function($event){}}},[_vm._v(_vm._s(item.rehabilitacion))])],1):_vm._e()]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"mdi-alert"}},[_vm._v("\n        No se ha encontrado ningún resultado para \""+_vm._s(_vm.search)+"\".\n      ")])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"warning","icon":"mdi-alert"}},[_vm._v("\n        No hay información de recipientes para mostrar.\n      ")])]},proxy:true},{key:"pageText",fn:function(props){return [_vm._v("\n      Filas "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+"\n    ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }