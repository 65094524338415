<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-alert
            v-if="updatable"
            border="left"
            colored-border
            color="warning"
            icon="mdi-alert"
          >
            Para guardar los cambios realizados, es necesario Actualizar
          </v-alert>
          <v-text-field
            v-model="nombre"
            placeholder="Ingrese un nombre"
            label="Nombre"
            :rules="rules.nombre"
            prepend-icon="mdi-text"
            color="primary"
            clearable
          ></v-text-field>
        </v-container>
        <v-footer>
          <v-btn
            @click="onUpdateSubmit"
            color="success"
            :loading="loading"
            :disabled="!updatable"
            v-show="$can('fabricantes.update')"
          >
            Actualizar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="onDeleteSubmit"
            color="error"
            :loading="loading"
            v-show="$can('fabricantes.destroy')"
          >
            <v-icon left>mdi-delete</v-icon>
            Eliminar
          </v-btn>
        </v-footer>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      valid: false,
    }
  },
  mount () {
    // this.onClearEdited()
    // this.onClearSelected()
  },
  computed: {
    ...mapGetters('Fabricantes', ['edited', 'rules', 'loading', 'updatable']),
    nombre: {
      get () {
        return this.edited.nombre
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          nombre: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Fabricantes', ['onUpdate']),
    ...mapMutations('Fabricantes', ['setEdited']),
    onUpdateSubmit (event) {
      event.preventDefault()
      this.onUpdate().then(() => {
        this.$emit('success')
      })
    },
    onDeleteSubmit (event) {
      event.preventDefault()
      alert('Falta funcionalidad')
    }
  },
}
</script>