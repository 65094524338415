var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.all,"footer-props":{
      itemsPerPageText: 'Filas por página',
      itemsPerPageOptions: [10, 30, 90]
    },"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemsLength,"items-per-page":10,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
    var item = ref.item;
return [(item.id)?_c('div',[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.onSubmitDelete(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()]}},{key:"item.recipiente.fabricante",fn:function(ref){
    var item = ref.item;
return [(item.recipiente.fabricante)?_c('div',[_vm._v("\n        "+_vm._s(item.recipiente.fabricante.nombre)+"\n      ")]):_vm._e()]}},{key:"item.recipiente.tipo",fn:function(ref){
    var item = ref.item;
return [(item.recipiente.tipo)?_c('div',[_vm._v("\n        "+_vm._s(item.recipiente.tipo.abreviatura)+"\n      ")]):_vm._e()]}},{key:"item.recipiente.vencimiento_ph",fn:function(ref){
    var item = ref.item;
return [(item.recipiente.vencimiento_ph)?_c('div',[_vm._v("\n        "+_vm._s(_vm._f("moment")(item.recipiente.vencimiento_ph,'DD/MM/YYYY'))+"\n      ")]):_vm._e()]}},{key:"item.recipiente.aprobado_ph",fn:function(ref){
    var item = ref.item;
return [(item.recipiente.aprobado_ph)?_c('div',[_vm._v("\n        "+_vm._s(_vm._f("moment")(item.recipiente.aprobado_ph,'DD/MM/YYYY'))+"\n      ")]):_vm._e()]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"color":"error","icon":"mdi-alert"}},[_vm._v("\n        No se ha encontrado ningún resultado para \""+_vm._s(_vm.search)+"\".\n      ")])]},proxy:true},{key:"no-data",fn:function(){return [(!_vm.certificadoRehabilitanteId)?_c('v-alert',{attrs:{"color":"warning","border":"left","colored-border":"","icon":"mdi-alert"}},[_vm._v("\n        No hay Certificado Rehabilitante seleccionado. No se puede agregar recipientes\n      ")]):_c('v-alert',{attrs:{"color":"warning","icon":"mdi-alert"}},[_vm._v("\n        Actualmente no existen recipientes asociados.\n      ")])]},proxy:true},{key:"pageText",fn:function(props){return [_vm._v("\n      Filas "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+"\n    ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }