const URL = '/comodatos_tanques';

const state = {
  all: [],
  edited: {
    id: null,
    numero: null,
    capacidad: null,
    comodato_id: null,
    recipiente_tipo_id: null,
  },
  errors: [],
  selected: {
    id: null,
    numero: null,
    capacidad: null,
    comodato_id: null,
    recipiente_tipo_id: null,
  },
  default: {
    id: null,
    numero: null,
    capacidad: null,
    comodato_id: null,
    recipiente_tipo_id: null,
  },
  filters: {
    numero: null,
    comodato_id: null,
    recipiente_id: null,
  },
  loading: false,
  headers: [
    {
      text: 'Número',
      value: 'numero',
      align: 'center'
    },
    {
      text: 'Capacidad',
      value: 'capacidad',
      align: 'center'
    },
  ],
  rules: {
    numero: [
      v => !!v || 'Este campo es necesario',
    ],
    capacidad: [
      v => !!v || 'Este campo es necesario',
    ],
    recipiente_id: [
      // v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir un recipiente seleccionado',
    ],
    comodato_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir un comodato seleccionado',
    ],
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  errors (state) {
    return state.errors
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  filters (state) {
    return state.filters
  },
  rules (state) {
    return state.rules
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
  updatable (state) {
    return state.edited.id === state.selected.id && (
      state.edited.numero != state.selected.numero ||
      state.edited.capacidad != state.selected.capacidad
    )
  },
}

// ACTIONS
const actions = {
  onClearEdited ({ state, commit }) {
    commit('setEdited', state.default)
  },
  onClearSelected ({ state, commit }) {
    commit('setSelected', state.default)
  },
  onClearFilters ({ commit }) {
    commit('setFilters', {
      comodato_id: null,
      numero: null,
      recipiente_id: null,
    })
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          numero: state.filters.numero,
          comodato_id: state.filters.comodato_id,
          recipiente_id: state.filters.recipiente_id,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            ...state.edited,
            id: response.data.data.id,
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('setErrors', [])
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el tanque', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          if (error.response) {
            commit('setErrors', [error.response.data.message])
          }
          commit('ResponseHTTP/addError', 'Error al guardar el tanque', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('setErrors', [])
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el tanque', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          if (error.response) {
            commit('setErrors', [error.response.data.message])
          }
          commit('ResponseHTTP/addError', 'Error al guardar el tanque', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se ha borrado el tanque', { root: true })
          dispatch('onClearEdited')
          dispatch('onClearSelected')
          commit('setErrors', [])
          resolve(response.data)
        })
        .catch(error => {
          if (error.data && !error.data.success) commit('setErrors', [error.data.message])
          commit('ResponseHTTP/addError', 'Error al borrar el tanque', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
  setErrors (state, payload) {
    state.errors = payload.slice()
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
