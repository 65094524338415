<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="collection"
      :search="search"
      single-select
      show-select
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
      disable-sort
    >
      <template v-slot:top>
        <v-toolbar flat dark extended :color="colorToolbar">
          <v-text-field
            v-model="searchText"
            label="Escriba"
            flat
            solo-inverted
            hide-details
            clearable
            @keydown.enter="onFetch"
            @click:clear="onClear"
          >
            <template v-slot:append>
              <v-btn
                color="primary"
                @click="onFetch"
                :disabled="!searchText || searchText.length == 0"
              >
                Buscar
              </v-btn>
            </template>
          </v-text-field>

          <template v-slot:extension>
            <v-scroll-x-reverse-transition>
              <v-btn
                v-if="selected.length && $can('zonas.update')"
                :disabled="selected.length > 1"
                text
                color="warning"
                @click="onEdit(selected)"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-pencil</v-icon>
                <div class="hidden-xs-only">Editar</div>
              </v-btn>
            </v-scroll-x-reverse-transition>
            <v-scroll-x-reverse-transition>
              <v-btn
                v-if="selected.length && $can('zonas.destroy')"
                text
                color="error"
                @click="onDelete(selected)"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-delete</v-icon>
                <div class="hidden-xs-only">Borrar</div>
              </v-btn>
            </v-scroll-x-reverse-transition>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="onCreate"
              v-if="$can('zonas.store')"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
              <div class="hidden-xs-only">Agregar</div>
            </v-btn>
          </template>
        </v-toolbar>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de zonas para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      title: 'Listado de zonas',
      search: '',
      selected: [],
    }
  },
  created () {
    // this.onFetch()
  },
  computed: {
    ...mapGetters({
      collection: 'Zonas/all',
      headers: 'Zonas/headers',
      loading: 'Zonas/loading',
      pagination: 'Zonas/pagination',
      itemsLength: 'Zonas/itemsLength',
      optionsGetter: 'Zonas/options',
      filters: 'Zonas/filters',
    }),
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilterSearchText(value)
      }
    },
    colorToolbar () {
      return this.selected.length ? 'listSelected' : 'toolbarList'
    },
  },
  methods: {
    ...mapActions({
      onFetch: 'Zonas/onFetch',
    }),
    ...mapMutations({
      setFilterSearchText: 'Zonas/setFilterSearchText',
      setOptions: 'Zonas/setOptions',
      setSelected: 'Zonas/setSelected',
    }),
    onCreate () {
      this.$router.push({ name: 'zonas-create' })
    },
    onEdit (selected) {
      this.$router.push({ name: 'zonas-edit', params: { id: selected[0].id }})
    },
    onDelete (selected) {
      this.$router.push({ name: 'zonas-delete', params: { id: selected[0].id }})
    },
    onClear () {
      this.setFilterSearchText()
      this.onFetch()
    }
  }
}
</script>
