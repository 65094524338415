<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-text-field
        v-model="formattedDate"
        :label="label"
        :placeholder="placeholder"
        :prepend-icon="!hideIcon ? 'mdi-calendar' : ''"
        :rules="rules"
        :clearable="clearable"
        @click:clear="formattedDate = null"
        :error-messages="dateErrorMessages"
        :counter="10"
      ></v-text-field>
    </v-form>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },

  props: {
    selected: String,
    label: String,
    placeholder: String,
    rules: Array,
    clearable: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    resetForm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formattedDate: null,
      dateErrorMessages: [],
      valid: false
    }
  },
  mounted () {
    if (this.selected) {
      const [datePart, timePart] = this.selected.split(' '); // Dividir la fecha y la hora
      const [year, month, day] = datePart.split('-'); // Dividir la parte de la fecha en año, mes y día
      this.formattedDate = `${day}/${month}/${year}`; // Formatear la fecha en formato DD/MM/YYYY
    }
  },
  watch: {
    formattedDate (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        const valueAux = String(newVal)

        const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
        if (!dateRegex.test(valueAux)) {
          this.dateErrorMessages = ['Formato de fecha inválido (DD/MM/AAAA)'];
          this.$emit('change', null)
        } else {
          this.dateErrorMessages = [];
          const [day, month, year] = valueAux.split('/'); // Dividir la parte de la fecha en dia, mes y año
          const date = `${year}-${month}-${day}`; // Formatear la fecha en formato DD/MM/YYYY
          this.$emit('change', this.$moment(date).format('YYYY-MM-DD HH:mm:ss'))
        }
      }
    },
    selected (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        const [datePart, timePart] = newVal.split(' '); // Dividir la fecha y la hora
        const [year, month, day] = datePart.split('-'); // Dividir la parte de la fecha en año, mes y día
        this.formattedDate = `${day}/${month}/${year}`; // Formatear la fecha en formato DD/MM/YYYY
      }
    },
    resetForm (value) {
      if (value) this.$refs.form.reset()
    },
  },
  methods: {
    //
  },
}
</script>