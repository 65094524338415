const URL = '/adjuntos';

const state = {
  loading: false,
  prefijo: null,
  formData: null,
}

// GETTERS
const getters = {
  loading (state) {
    return state.loading
  }
}

// ACTIONS
const actions = {
  onUpload({ commit }, formData) {
    commit('setLoading', true);
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDownload({ commit }, url) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/' + url, {
          responseType: 'blob', // Especificamos que esperamos una respuesta de tipo blob (archivo binario)
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  }
}

// MUTATIONS
const mutations = {
  setLoading (state, payload) {
    state.loading = payload
  },
  setFormData (state, payload) {
    state.formData = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
