const URL = '/clientes';

const state = {
  all: [],
  found: [],
  selected: {
    id: null,
    documento: null,
    razon_social: null,
    apellido: null,
    nombre: null,
    email: null,
    telefono_uno: null,
    telefono_dos: null,
    comodato: null,
    inactivo: null,
  },
  filters: {
    searchText: null,
    documento: null,
    razon_social: null,
    apellido: null,
    nombre: null,
    email: null,
    telefono_uno: null,
    telefono_dos: null,
    comodato: null,
    inactivo: null,
  },
  edited: {
    id: null,
    documento: null,
    razon_social: null,
    apellido: null,
    nombre: null,
    email: null,
    telefono_uno: null,
    telefono_dos: null,
    comodato: null,
    inactivo: null,
  },
  default: {
    id: null,
    documento: null,
    razon_social: null,
    apellido: null,
    nombre: null,
    email: null,
    telefono_uno: null,
    telefono_dos: null,
    comodato: null,
    inactivo: null,
  },
  loading: false,
  headers: [
    {
      text: 'Accion',
      value: 'id',
      align: 'center'
    },
    {
      text: 'Documento',
      value: 'documento',
      align: 'center'
    },
    {
      text: 'Apellido y nombre',
      value: 'razon_social',
      align: 'center'
    },
    {
      text: 'Recipientes',
      value: 'recipientes',
      align: 'center'
    },
    // {
    //   text: 'Apellido',
    //   value: 'apellido',
    //   align: 'center'
    // },
    // {
    //   text: 'Nombre',
    //   value: 'nombre',
    //   align: 'center'
    // },
    // {
    //   text: 'Email',
    //   value: 'email',
    //   align: 'center'
    // },
    {
      text: 'Telefono',
      value: 'telefono_uno',
      align: 'center'
    },
    // {
    //   text: 'Inactivo',
    //   value: 'inactivo',
    //   align: 'center'
    // },
  ],
  rules: {
    documento: [
      v => !!v || 'Este campo es necesario',
    ],
    razon_social: [
      v => !!v || 'Este campo es necesario',
    ],
    apellido: [
      // v => !!v || 'Este campo es necesario',
    ],
    nombre: [
      // v => !!v || 'Este campo es necesario',
    ],
    email: [
      // v => !!v || 'Este campo es necesario',
    ],
    telefono_uno: [
      // v => !!v || 'Este campo es necesario',
    ],
    telefono_dos: [
      // v => !!v || 'Este campo es necesario',
    ],
    inactivo: [
      // v => !!v || 'Este campo es necesario',
    ],
  },
  default: {
    id: null,
    documento: null,
    razon_social: null,
    apellido: null,
    nombre: null,
    email: null,
    telefono_uno: null,
    telefono_dos: null,
    inactivo: null,
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  found (state) {
    return state.found
  },
  edited (state) {
    return state.edited
  },
  filters (state) {
    return state.filters
  },
  selected (state) {
    return state.selected
  },
  isSelected (state) {
    return state.edited.id > 0
  },
  updatable (state) {
    return state.edited.id == state.selected.id && (
            state.edited.documento != state.selected.documento ||
            state.edited.razon_social != state.selected.razon_social ||
            state.edited.email != state.selected.email ||
            state.edited.telefono_uno != state.selected.telefono_uno ||
            state.edited.telefono_dos != state.selected.telefono_dos ||
            state.edited.inactivo != state.selected.inactivo
          )
  },
  rules (state) {
    return state.rules
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  default (state) {
    return state.default
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onClearSelected ({ state, commit }) {
    commit('setSelected', state.default)
  },
  onClearEdited ({ state, commit }) {
    commit('setEdited', state.default)
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
          searchText: state.filters.searchText,
          documento: state.filters.documento,
          razon_social: state.filters.razon_social,
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/' + payload)
        .then(response => {
          const aux = {
            ...state.edited,
            id: response.data.id,
            documento: response.data.documento,
            razon_social: response.data.razon_social,
            apellido: response.data.apellido,
            nombre: response.data.nombre,
            email: response.data.email_uno,
            telefono_uno: response.data.telefono_uno,
            telefono_dos: response.data.telefono_dos,
            inactivo: response.data.inactivo
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSearch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/busqueda/' + state.filters.searchText)
        .then(response => {
          commit('setFound', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            ...state.edited,
            id: response.data.data.id,
            documento: response.data.data.documento,
            razon_social: response.data.data.razon_social,
            apellido: response.data.data.apellido,
            nombre: response.data.data.nombre,
            email: response.data.data.email_uno,
            telefono_uno: response.data.data.telefono_uno,
            telefono_dos: response.data.data.telefono_dos,
            inactivo: response.data.data.inactivo
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el cliente', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el cliente', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el cliente', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el cliente', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se ha borrado el cliente', { root: true })
          commit('setEdited', state.default)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al borrar el cliente', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setFound (state, payload) {
    state.found = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
