var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.all,"show-select":false,"footer-props":{
      itemsPerPageText: 'Filas por página',
      itemsPerPageOptions: [10,15,30]
    },"loading":_vm.loading,"options":_vm.optionsLocal,"server-items-length":_vm.itemsLength,"items-per-page":10,"disable-sort":""},on:{"update:options":function($event){_vm.optionsLocal=$event},"click:row":_vm.onSelect},scopedSlots:_vm._u([{key:"item.firmado",fn:function(ref){
    var item = ref.item;
return [(item.firmado)?_c('div',[_vm._v("\n        "+_vm._s(_vm._f("moment")(item.firmado,'DD/MM/YYYY'))+"\n      ")]):_vm._e()]}},{key:"item.vencimiento",fn:function(ref){
    var item = ref.item;
return [(item.vencimiento)?_c('div',[_vm._v("\n        "+_vm._s(_vm._f("moment")(item.vencimiento,'DD/MM/YYYY'))+"\n      ")]):_vm._e()]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"mdi-alert"}},[_vm._v("\n        No se ha encontrado ningún resultado para \""+_vm._s(_vm.search)+"\".\n      ")])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"warning","icon":"mdi-alert"}},[_vm._v("\n        No hay información de comodatos para mostrar.\n      ")])]},proxy:true},{key:"pageText",fn:function(props){return [_vm._v("\n      Filas "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+"\n    ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }