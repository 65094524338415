<template>
  <div>
    <v-sheet>
      <v-container>
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="isSelected ? 'primary' : ''">mdi-check-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tanques instalados</v-list-item-title>
        </v-list-item>
        <v-alert
          border="left"
          colored-border
          color="primary"
          icon="mdi-warning"
        >
          Aqui se muestran los tanques provisorios (numero y capacidad) del comodato
        </v-alert>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            @click="onCreateSubmit"
            color="primary"
          >
            <v-icon left>mdi-plus</v-icon>
            Tanque Nuevo
          </v-btn>
        </v-toolbar>
        <my-comodatos-tanques-datatable
          v-model="tanqueId"
          :comodato-id="comodatoId"
          @select="onSelect"
        ></my-comodatos-tanques-datatable>
      </v-container>
      <v-navigation-drawer
        v-model="drawerLocal"
        right
        app
        temporary
        width="350px"
      >
        <v-toolbar flat dense>
          <v-btn
            @click="drawerLocal = false"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-subheader>Caracteristicas del tanque</v-subheader>
        </v-toolbar>
        <v-alert
          border="left"
          colored-border
          color="error"
          icon="mdi-alert"
          v-show="hasErrors"
        >
          <div v-for="(item, index) in errors" :key="index">{{ item }}</div>
        </v-alert>
        <!-- TanqueEdit -->
        <div v-show="isSelected">
          <my-comodato-tanque-edit
            v-model="tanqueId"
            @updated="onSuccess"
            @deleted="onSuccess"
            :comodato-id="comodatoId"
          ></my-comodato-tanque-edit>
        </div>
        <!-- TanqueCreate -->
        <div v-show="!isSelected">
          <my-comodato-tanque-create
            v-model="tanqueId"
            :comodato-id="comodatoId"
            @created="onSuccess"
          ></my-comodato-tanque-create>
        </div>
      </v-navigation-drawer>
    </v-sheet>

  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TanquesDatatable from '@/pages/comodatos_tanques/Datatable'
import TanquesCreate from '@/pages/comodatos_tanques/Create'
import TanquesEdit from '@/pages/comodatos_tanques/Edit'
export default {
  components: {
    'my-comodatos-tanques-datatable': TanquesDatatable,
    'my-comodato-tanque-create': TanquesCreate,
    'my-comodato-tanque-edit': TanquesEdit,
  },
  props: {
    comodatoId: Number
  },
  data () {
    return {
      tanqueId: null,
      sync: false,
      drawerLocal: false,
    }
  },
  beforeDestroy () {
    this.$store.dispatch('ComodatosTanques/onClearEdited')
    this.$store.dispatch('ComodatosTanques/onClearSelected')
  },
  created () {
    // this.onDefaults()
  },
  computed: {
    edited: {
      get () {
        return this.$store.getters['ComodatosTanques/edited']
      },
      set (value) {
        this.$store.commit('ComodatosTanques/setEdited', value)
      }
    },
    isSelected () {
      return this.edited && this.edited.id > 0
    },
    filters: {
      get () {
        return this.$store.getters['ComodatosTanques/filters']
      },
      set (value) {
        this.$store.commit('ComodatosTanques/setFilters', value)
      }
    },
    hasErrors () {
      return this.errors && this.errors.length > 0 ? true : false
    },
    errors () {
      return this.$store.getters['ComodatosTanques/errors']
    },
  },
  watch: {
    filters (value) {
      if (value.comodato_id) this.onFetch()
    },
    errors (value) {
      // alert(JSON.stringify(value))
    }
  },
  methods: {
    onValidate (value) {
      if (value) this.$emit('complete', value)
    },
    onDelete (item) {
      // alert('delete')
    },
    onFetch () {
      return this.$store.dispatch('ComodatosTanques/onFetch')
    },
    onSuccess () {
      this.onFetch()
      this.drawerLocal = false
    },
    onSelect () {
      this.drawerLocal = true
      this.$store.commit('ComodatosTanques/setErrors', [])
    },
    onCreateSubmit () {
      this.$store.dispatch('ComodatosTanques/onClearEdited')
      this.$store.dispatch('ComodatosTanques/onClearSelected')
      this.$store.commit('ComodatosTanques/setErrors', [])
      this.edited = {
        ...this.edited,
        comodato_id: this.filters.comodato_id
      }
      this.drawerLocal = true
    }
  },
}
</script>
