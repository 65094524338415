<template>
  <div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: {
    certificadoRehabilitanteId: {
      type: Number,
      default: null
    },
    recipienteId: {
      type: Number,
      default: null
    },
  },
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters('RehabilitacionesRecipientes', ['edited']),
  },
  watch: {
    certificadoRehabilitanteId (value) {
      this.setEdited({
        ...this.edited,
        certificado_rehabilitante_id: value
      })
      if (this.recipienteId > 0 && value > 0) {
        this.onSubmit()
      }
    },
    recipienteId (value) {
      this.setEdited({
        ...this.edited,
        recipiente_id: value
      })
      if (this.certificadoRehabilitanteId > 0 && value > 0) {
        this.onSubmit()
      }
    },
  },
  methods: {
    ...mapActions('RehabilitacionesRecipientes', ['onSave', 'onFetch']),
    ...mapMutations('RehabilitacionesRecipientes', ['setEdited']),
    onSubmit () {
      this.onSave().then(response => {
        this.onFetch()
      })
    }
  }
}
</script>
