<template>
  <div>
    <v-navigation-drawer
      v-model="drawerLocal"
      right
      app
      temporary
      width="350px"
    >
      <v-toolbar flat dense>
        <v-btn
          @click="drawerLocal = false"
          icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-subheader>Caracteristicas del recipiente</v-subheader>
      </v-toolbar>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-text-field
            v-model="numero"
            type="number"
            prepend-icon="mdi-ray-start-arrow"
            label="Número"
            :rules="rules.numero"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="capacidad"
            type="number"
            label="Capacidad"
            :rules="rules.capacidad"
            color="primary"
          >
            <template v-slot:prepend>
              <div>m<sup>3</sup></div>
            </template>
          </v-text-field>
          <v-text-field
            v-model="presionDisenio"
            type="number"
            placeholder="Ingrese presión de diseño en kg/cm2"
            label="Presión diseño"
            :rules="rules.presion_disenio"
            prepend-icon="mdi-decimal"
            color="primary"
          >
            <template v-slot:prepend>
              <div><sup>kg</sup>/<sub>cm<sup>2</sup></sub></div>
            </template>
          </v-text-field>
          <v-text-field
            v-model="presionPh"
            type="number"
            placeholder="Ingrese presión de prueba hidrálica"
            label="Presión PH"
            :rules="rules.presion_ph"
            prepend-icon="mdi-decimal"
            color="primary"
          >
            <template v-slot:prepend>
              <div><sup>kg</sup>/<sub>cm<sup>2</sup></sub></div>
            </template>
          </v-text-field>
          <v-text-field
            v-model="volumen"
            type="number"
            label="Volumen de agua"
            :rules="rules.volumen"
            color="primary"
          >
            <template v-slot:prepend>
              <div>lts</div>
            </template>
          </v-text-field>
          <my-periodo-input
            v-model="aprobadoPh"
            :resetForm="resetForm"
            label="Fecha de PH"
            :rules="rules.aprobado_ph"
          ></my-periodo-input>
          <my-periodo-input
            v-model="vencimientoPh"
            :resetForm="resetForm"
            label="Fecha de vencimiento PH"
            :rules="rules.vencimiento_ph"
          ></my-periodo-input>
          <my-recipiente-tipo-select
            v-model="tipo"
            :rules="rules.recipiente_tipo_id"
          ></my-recipiente-tipo-select>
          <my-fabricante-select
            v-model="fabricante"
            :rules="rules.fabricante_id"
          ></my-fabricante-select>
          <!--
          <v-alert
            border="left"
            colored-border
            color="primary"
            icon="mdi-information"
          >
            Para multiples registros, completar campos Número desde y Número hasta. Para un solo registro, completar solo el campo Número desde
          </v-alert>
          -->
          <v-btn
            color="primary"
            @click="onSubmitActualizar"
            block
          >
            Actualizar
          </v-btn>
          <v-divider class="mx-auto"></v-divider>
          <v-btn
            color="error"
            @click="onSubmitDelete"
            block
          >
            <v-icon>mdi-delete</v-icon>
            Eliminar
          </v-btn>          
        </v-container>
      </v-form>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import PeriodoInput from '@/components/PeriodoInput'
import RecipienteTipoSelect from '@/pages/recipientes_tipos/Select'
import FabricanteSelect from '@/pages/fabricantes/Select'
export default {
  components: {
    'my-periodo-input': PeriodoInput,
    'my-recipiente-tipo-select': RecipienteTipoSelect,
    'my-fabricante-select': FabricanteSelect
  },
  model: {
    prop: 'drawer',
    event: 'change'
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    resetForm: {
      type: Boolean,
      default: null
    },
  },
  data () {
    return {
      valid: true,      
      panelOpen: [1], // Inicialmente cerrado
    }
  },
  created () {
    this.onClearEdited()
    this.onClearSelected()
  },
  computed: {
    ...mapGetters('Recipientes', ['edited', 'rules', 'loading']),
    isSelected () {
      return this.edited.id != null && this.edited.id != undefined
    },
    drawerLocal: {
      get () {
        return this.drawer
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          numero: value
        })
      }
    },
    capacidad: {
      get () {
        return this.edited.capacidad
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          capacidad: value
        })
      }
    },
    volumen: {
      get () {
        return this.edited.volumen
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          volumen: value
        })

      }
    },
    aprobadoPh: {
      get () {
        return this.edited.aprobado_ph
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          aprobado_ph: value
        })
      }
    },
    vencimientoPh: {
      get () {
        return this.edited.vencimiento_ph
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          vencimiento_ph: value
        })
      }
    },
    presionDisenio: {
      get () {
        return this.edited.presion_disenio
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          presion_disenio: value
        })
      }
    },
    presionPh: {
      get () {
        return this.edited.presion_ph
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          presion_ph: value
        })
      }
    },
    tipo: {
      get () {
        return this.edited.recipiente_tipo_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          recipiente_tipo_id: value
        })
      }
    },
    fabricante: {
      get () {
        return this.edited.fabricante_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          fabricante_id: value
        })
      }
    },
    certificadoFabricante: {
      get () {
        return this.edited.certificado_fabricante_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          certificado_fabricante_id: value
        })
      }
    },
  },
  watch: {
    // certificadoFabricanteId (value) {
    //   this.certificadoFabricante = value
    // },
    // resetForm (value) {
    //   // if (value) this.$refs.form.reset()
    //   this.numero = null
    //   this.numeroHasta = null
    // },
  },
  methods: {
    ...mapActions('Recipientes', ['onUpdate', 'onDelete', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Recipientes', ['setEdited']),
    onSubmitActualizar (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.onUpdate().then(() => {
          this.$emit('change', false)
          this.$emit('updated')
          // this.$router.push({ name: 'recipientes-main' })
        })
      }
    },
    onSubmitDelete (event) {
      event.preventDefault()
      this.onDelete().then(() => {
        this.$emit('change', false)
        this.$emit('deleted')
        // this.$router.push({ name: 'recipientes-main' })
      })
    }
  },
}
</script>
