<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="nombre"
                placeholder="Ingrese un nombre"
                label="Nombre"
                :rules="rules.nombre"
                prepend-icon="mdi-text"
                color="primary"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-footer>
            <v-btn
              @click="onSaveSubmit"
              color="success"
              :loading="loading"
            >
              Registrar
            </v-btn>
          </v-footer>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
// import ZonaSelect from '@/pages/zonas/Select'
export default {
  data () {
    return {
      valid: false,
    }
  },
  computed: {
    ...mapGetters('Fabricantes', ['edited', 'rules', 'loading']),
    nombre: {
      get () {
        return this.edited.nombre
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          nombre: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Fabricantes', ['onClearEdited', 'onClearSelected', 'onSave']),
    ...mapMutations('Fabricantes', ['setEdited']),
    onSaveSubmit (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(() => {
          this.$emit('success')
        })
      }
    },
  },
}
</script>