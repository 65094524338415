<template>
  <div>
    <v-navigation-drawer
      v-model="drawerLocal"
      right
      app
      temporary
      width="350px"
    >
      <v-toolbar flat dense>
        <v-btn
          @click="onClickClose"
          icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-subheader>Caracteristicas del proveedor</v-subheader>
      </v-toolbar>
      <my-proveedor-create
        v-show="!isSelected"
        @success="onEventSuccess"
      ></my-proveedor-create>
      <my-proveedor-edit
        v-show="isSelected"
        @success="onEventSuccess"
      ></my-proveedor-edit>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ProveedorCreate from '@/pages/proveedores/Create'
import ProveedorEdit from '@/pages/proveedores/Edit'
export default {
  components: {
    'my-proveedor-create': ProveedorCreate,
    'my-proveedor-edit': ProveedorEdit
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
    }
  },
  created () {
    this.onClearEdited()
    this.onClearSelected()
  },
  computed: {
    ...mapGetters('Proveedores', ['edited', 'loading']),
    isSelected () {
      return this.edited.id && this.edited.id > 0
    },
    drawerLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
  },
  watch: {
    //
  },
  methods: {
    ...mapActions('Proveedores', ['onFetch', 'onClearEdited', 'onClearSelected']),
    onEventSuccess () {
      this.onFetch()
      this.drawerLocal = false
    },
    onClickClose (event) {
      event.preventDefault()
      this.drawerLocal = false
    }
  },
}
</script>