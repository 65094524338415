<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10,30]
      }"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
      :items-per-page="10"
      disable-sort
      @click:row="onSelect"
    >
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de tanques para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Number,
    comodatoId: Number
  },
  data () {
    return {
      //
    }
  },
  created () {
    //
  },
  computed: {
    filters: {
      get () {
        return this.$store.getters['ComodatosTanques/filters']
      },
      set (value) {
        this.$store.commit('ComodatosTanques/setFilters', value)
      }
    },
    options: {
      get () {
        return this.$store.getters['ComodatosTanques/options']
      },
      set (value) {
        this.$store.commit('ComodatosTanques/setOptions', value)
        if (this.filters.comodato_id) this.onFetch()
      }
    },
    all () {
      return this.$store.getters['ComodatosTanques/all']
    },
    headers () {
      return this.$store.getters['ComodatosTanques/headers']
    },
    loading () {
      return this.$store.getters['ComodatosTanques/loading']
    },
    itemsLength () {
      return this.$store.getters['ComodatosTanques/itemsLength']
    },
  },
  watch: {
    comodatoId (value) {
      this.filters = {
        ...this.filters,
        comodato_id: value
      }
      if (value) this.onFetch()
    },
  },
  methods: {
    onFetch () {
      return this.$store.dispatch('ComodatosTanques/onFetch')
    },
    onSelect (item) {
      /* Se cargan los datos en la variable Seleccionado para comparar */
      const aux = {
        ...this.$store.getters['ComodatosTanques/edited'],
        id: item.id,
        numero: item.numero,
        capacidad: item.capacidad,
        comodato_id: item.comodato_id,
        recipiente_tipo_id: item.recipiente_tipo_id,
      }
      this.$store.commit('ComodatosTanques/setEdited', aux)
      this.$store.commit('ComodatosTanques/setSelected', aux)
      this.$emit('change', item.id)
      this.$emit('select')
    },
  }
}
</script>
