<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-alert
            v-if="updatable"
            border="left"
            colored-border
            color="warning"
            icon="mdi-alert"
          >
            Para guardar los cambios realizados, es necesario Actualizar
          </v-alert>
          <v-text-field
            v-model="cuit"
            placeholder="Ingrese un CUIT"
            label="CUIT"
            :rules="rules.cuit"
            prepend-icon="mdi-text"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="razon_social"
            placeholder="Ingrese un razon social"
            label="Razon social"
            :rules="rules.razon_social"
            prepend-icon="mdi-text"
            color="primary"
          ></v-text-field>
        </v-container>
        <v-footer>
          <v-btn
            @click="onUpdateSubmit"
            color="success"
            :loading="loading"
            :disabled="!updatable"
            v-show="$can('proveedores.update')"
          >
            Actualizar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="onDeleteSubmit"
            color="error"
            :loading="loading"
            v-show="$can('proveedores.destroy')"
          >
            <v-icon left>mdi-delete</v-icon>
            Eliminar
          </v-btn>
        </v-footer>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      valid: false,
    }
  },
  mount () {
    // this.onClearEdited()
    // this.onClearSelected()
  },
  computed: {
    ...mapGetters('Proveedores', ['edited', 'rules', 'loading', 'updatable']),
    cuit: {
      get () {
        return this.edited.cuit
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cuit: value
        })
      }
    },
    razon_social: {
      get () {
        return this.edited.razon_social
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          razon_social: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Proveedores', ['onUpdate']),
    ...mapMutations('Proveedores', ['setEdited']),
    onUpdateSubmit (event) {
      event.preventDefault()
      this.onUpdate().then(() => {
        this.$emit('success')
      })
    },
    onDeleteSubmit (event) {
      event.preventDefault()
      alert('Falta funcionalidad')
    }
  },
}
</script>