const URL = '/rehabilitantes_recipientes';

const state = {
  all: [],
  found: [],
  selected: {
    id: null,
    certificado_rehabilitante_id: null,
    recipiente_id: null,
    recipiente: null,
    user_id: null,
  },
  filters: {
    searchText: null,
    certificado_rehabilitante_id: null,
    certificado_fabricante_id: null,
    recipiente_id: null,
    user_id: null,
  },
  edited: {
    id: null,
    certificado_rehabilitante_id: null,
    recipiente_id: null,
    recipiente: null,
    user_id: null,
  },
  default: {
    id: null,
    certificado_rehabilitante_id: null,
    recipiente_id: null,
    recipiente: null,
    user_id: null,
  },
  loading: false,
  headers: [
    {
      text: 'Numero',
      value: 'recipiente.numero',
      align: 'center'
    },
    {
      text: 'Fabricante',
      value: 'recipiente.fabricante',
      align: 'center'
    },
    {
      text: 'Tipo',
      value: 'recipiente.tipo',
      align: 'center'
    },
    {
      text: 'Capacidad (m3)',
      value: 'recipiente.capacidad',
      align: 'center'
    },
    {
      text: 'Volumen (Lts)',
      value: 'recipiente.volumen',
      align: 'center'
    },
    {
      text: 'Presión diseño (Kg/cm2)',
      value: 'recipiente.presion_disenio',
      align: 'center'
    },
    {
      text: 'Presión PH (Kg/cm2)',
      value: 'recipiente.presion_ph',
      align: 'center'
    },
    {
      text: 'Fecha PH',
      value: 'recipiente.aprobado_ph',
      align: 'center'
    },
    {
      text: 'Vencimiento PH',
      value: 'recipiente.vencimiento_ph',
      align: 'center'
    },
  ],
  rules: {
    certificado_rehabilitante_id: [
      v => !!v || 'Este campo es necesario',
      v => _.isInteger(v) || 'Debe existir un certificado rehabilitante seleccionado',
    ],
    recipiente_id: [
      v => !!v || 'Este campo es necesario',
      v => _.isInteger(v) || 'Debe existir un recipiente seleccionado',
    ],
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  filters (state) {
    return state.filters
  },
  selected (state) {
    return state.selected
  },
  rules (state) {
    return state.rules
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onClearSelected ({ state, commit }) {
    commit('setSelected', state.default)
  },
  onClearEdited ({ state, commit }) {
    commit('setEdited', state.default)
  },
  onClearFilters ({ state, commit }) {
    commit('setFilters', {
      ...state.filters,
      searchText: null,
      certificado_fabricante_id: null,
      certificado_rehabilitante_id: null,
      recipiente_id: null,
      user_id: null,
    })
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
          params: {
            itemsPerPage: state.options.itemsPerPage,
            page: state.options.page,
            sortBy: state.options.sortBy[0] || '',
            sortDesc: state.options.sortDesc[0] ? 1 : 0,
            certificado_rehabilitante_id: state.filters.certificado_rehabilitante_id,
            certificado_fabricante_id: state.filters.certificado_fabricante_id,
            recipiente_id: state.filters.recipiente_id,
          }
        })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          commit('setAll', [])
          commit('setItemsLength', 0)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/' + payload)
        .then(response => {
          commit('setEdited', response.data)
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            ...state.edited,
            id: response.data.id
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('ResponseHTTP/addSuccess', 'Se registró la relación entre el recipiente y el certificado rehabilitante', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al registrar la relación entre el recipiente y el certificado rehabilitante', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'Se actualizó la relación entre el recipiente y el certificado rehabilitante', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al actualizar la relación entre el recipiente y el certificado rehabilitante', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se eliminó la relación entre el recipiente y el certificado rehabilitante', { root: true })
          commit('setEdited', state.default)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al eliminar la relación entre el recipiente y el certificado rehabilitante', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
