<template>
  <div>
    <v-sheet
      v-show="filters.comodato_id"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <!--
          <v-alert
            border="left"
            colored-border
            color="warning"
            elevation="2"
          >
            Este formulario se utiliza para registrar los datos de Instalación
            <v-btn
              color="primary"
              @click="onSubmit"
              :loading="loading"
            >
              <v-icon left>mdi-keyboard-f4</v-icon>
              Registrar
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-alert>
          -->
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="orden"
                placeholder="Ingrese un número de órden"
                label="Orden"
                :rules="rules.orden"
                prepend-icon="mdi-counter"
                color="primary"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <my-date-picker
                v-model="realizado"
                label="Fecha de instalación"
                clearable
              ></my-date-picker>
            </v-col>
            <v-col cols="12" md="4">
              <my-tipo-instalacion-select
                v-model="tipoInstalacion"
              ></my-tipo-instalacion-select>
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            @click="onSubmit"
          >
            Actualizar
          </v-btn>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DateInput from '@/components/DateInput'
import TipoInstalacionSelect from '@/pages/tipos_instalaciones/Select'
export default {
  components: {
    'my-date-picker': DateInput,
    'my-tipo-instalacion-select': TipoInstalacionSelect,
  },
  data () {
    return {
      valid: true,
    }
  },
  created () {
    // this.onDefaults()
  },
  computed: {
    ...mapGetters('ComodatosInstalaciones', ['edited', 'rules', 'filters']),
    ...mapGetters('Comodatos', ['comodatoId']),
    realizado: {
      get () {
        return this.edited.realizado
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          realizado: value
        })
      }
    },
    orden: {
      get () {
        return this.edited.orden
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          orden: value
        })
      }
    },
    comodato: {
      get () {
        return this.edited.comodato_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          comodato_id: value
        })
        this.setFilters({
          ...this.filters,
          comodato_id: value
        })
      }
    },
    tipoInstalacion: {
      get () {
        return this.edited.tipo_instalacion_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          tipo_instalacion_id: value
        })
      }
    },
  },
  watch: {
    comodatoId (value) {
      // alert(value)
      this.comodato = value
      if (value) this.onFetch()
    }
  },
  methods: {
    ...mapActions('ComodatosInstalaciones', ['onUpdate', 'onFetch', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('ComodatosInstalaciones', ['setEdited', 'setFilters']),
    onSubmit (value) {
      this.$refs.form.validate()
      if (this.valid) {
        this.onUpdate().then(response => {
          // this.$emit('updated', this.edited.id)
        })
      }
    },
  },
}
</script>
