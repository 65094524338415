<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-text-field
            v-model="cuit"
            placeholder="Ingrese un cuit"
            label="CUIT"
            :rules="rules.cuit"
            prepend-icon="mdi-text"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="razon_social"
            placeholder="Ingrese una razon social"
            label="Razon social"
            :rules="rules.razon_social"
            prepend-icon="mdi-text"
            color="primary"
          ></v-text-field>
        </v-container>
        <v-footer>
          <v-btn
            @click="onSaveSubmit"
            color="success"
            :loading="loading"
          >
            Registrar
          </v-btn>
        </v-footer>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      valid: false,
    }
  },
  computed: {
    ...mapGetters('Proveedores', ['edited', 'rules', 'loading']),
    cuit: {
      get () {
        return this.edited.cuit
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cuit: value
        })
      }
    },
    razon_social: {
      get () {
        return this.edited.razon_social
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          razon_social: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Proveedores', ['onClearEdited', 'onClearSelected', 'onSave']),
    ...mapMutations('Proveedores', ['setEdited']),
    onSaveSubmit (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(() => {
          this.$emit('success')
        })
      }
    },
  },
}
</script>