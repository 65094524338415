<template>
  <div>
    <v-select
      v-model="selectedLocal"
      :items="all"
      hide-no-data
      item-text="razon_social"
      item-value="id"
      :rules="rules"
      prepend-icon="mdi-truck-cargo-container"
      :label="label"
      :placeholder="placeholder"
      :color="color"
      :readonly="readonly"
      :clearable="clearable"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Number,
    rules: Array,
    readonly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
  },
  data () {
    return {
      placeholder: 'Seleccione un proveedor',
      label: 'Proveedor'
    }
  },
  created () {
    this.onFetchSelect()
  },
  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },

      set (value) {
        this.$emit('change', value)
      }
    },
    ...mapGetters('Proveedores', ['all']),
  },
  methods: {
    ...mapActions('Proveedores', ['onFetchSelect']),
  }
}
</script>
