<template>
  <div>
    <v-expansion-panels v-model="panelOpen">
      <v-expansion-panel>
        <v-expansion-panel-header color="searchPanel">Buscar cliente</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="all"
            :footer-props="{
              itemsPerPageText: 'Filas por página'
            }"
            :loading="loading"
            :options.sync="options"
            :server-items-length="itemsLength"
            disable-sort
            @click:row="onSelect"
          >
            <template v-slot:top>
              <v-text-field
                v-model="searchText"
                placeholder="Escriba algún dato del cliente"
                @keydown.enter="onFetch"
                hint="Enter para buscar cliente"
              >
                <template v-slot:prepend>
                  <v-btn
                    icon
                    color="primary"
                    @click="onFetch"
                    :disabled="!searchText || searchText.length == 0"
                    :loading="loading"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </template>
            <template v-slot:item.inactivo="{ item }">
              {{ !item.inactivo ? 'Si' : 'No' }}
            </template>
            <template v-slot:item.razon_social="{ item }">
              <div
                v-if="item.razon_social"
              >
                {{ item.razon_social }}
              </div>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="mdi-alert">
                No se ha encontrado ningún resultado para "{{ searchText }}".
              </v-alert>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="warning" icon="mdi-alert">
                No hay información de clientes para mostrar.
              </v-alert>
            </template>
            <template v-slot:pageText="props">
              Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Number,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: Array,
  },
  data () {
    return {
      valid: false,
      placeholder: 'Busque por Número de documento ó razón social',
      attrs: {
        class: 'text-wrap',
      },
      panelOpen: undefined, // Inicialmente cerrado
      headers: [
        {
          text: 'Documento',
          value: 'documento',
          align: 'center'
        },
        {
          text: 'Apellido y nombre',
          value: 'razon_social',
          align: 'center'
        },
        // {
        //   text: 'Apellido',
        //   value: 'apellido',
        //   align: 'center'
        // },
        // {
        //   text: 'Nombre',
        //   value: 'nombre',
        //   align: 'center'
        // },
        {
          text: 'email',
          value: 'email',
          align: 'center'
        },
        {
          text: 'Telefono uno',
          value: 'telefono_uno',
          align: 'center'
        },
        {
          text: 'Telefono dos',
          value: 'telefono_dos',
          align: 'center'
        },
        {
          text: 'Inactivo',
          value: 'inactivo',
          align: 'center'
        },
      ],
    }
  },
  computed: {
    ...mapGetters('Clientes', ['all', 'loading', 'itemsLength', 'options', 'filters']),
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    }
  },
  watch: {
    panelOpen (value) {
      //
    }
  },
  methods: {
    ...mapActions('Clientes', ['onFetch', 'onClearSelected', 'onClearEdited']),
    ...mapMutations('Clientes', ['setAll', 'setEdited', 'setSelected', 'setFilters', 'setOptions']),
    onSelect (item) {
      /* Se cargan los datos en la variable Seleccionado para comparar */
      const aux = {
        ...this.edited,
        id: item.id,
        documento: item.documento,
        razon_social: item.razon_social,
        email: item.email,
        telefono_uno: item.telefono_uno,
        telefono_dos: item.telefono_dos,
        inactivo: item.inactivo
      }
      this.setEdited(aux)
      this.setSelected(aux)
      // Luego de completar la lógica, cierra el panel
      this.panelOpen = undefined;

      this.searchText = null
      this.setAll([])
      this.selectedLocal = item.id
      this.$emit('selected', item)
    },
    onClear () {
      this.searchText = null
      this.onFetch()
    },
    onClearSelect () {
      this.onClearSelected()
      this.onClearEdited()
      this.selectedLocal = null
      this.$emit('selected', null)
      this.setAll([])
    },
    onSubmit () {
      this.onFetch()
    }
  }
}
</script>
