<template>
  <div>
    <v-sheet
      v-if="showFilters"
    >
      <v-toolbar flat>
        <v-btn
          icon
          @click.stop="showFilters = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Filtros</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="onFetch"
          :disabled="anySelectedFilters"
          :loading="loading"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp" dark>mdi-check</v-icon>
          <div class="hidden-xs-only">Aplicar filtros</div>
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="onSubmitClearFilters"
          :disabled="anySelectedFilters"
          :loading="loading"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp" dark>mdi-filter-variant-remove</v-icon>
          <div class="hidden-xs-only">Limpiar filtros</div>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-subheader>Filtros para recipientes</v-subheader>
        <v-chip @click="onPhVencidas">PH vencidas</v-chip>
        <v-chip @click="onPhProximosVencimientos">PH proximos a vencer</v-chip>
        <v-chip @click="onLibreComodato">Libre de comodato</v-chip>
      </v-container>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="certificadoIdentificacion"
              label="Identificacion Certificado"
              messages="Enter para buscar"
              clearable
              prepend-icon="mdi-box"
              @keydown.enter="onInputIdentificacion"
              @click:clear="certificadoIdentificacion = null"
            ></v-text-field>
            <v-text-field
              v-model="numero"
              type="number"
              label="Número"
              messages="Enter para buscar"
              clearable
              prepend-icon="mdi-counter"
              @keydown.enter="onFetch"
              @click:clear="numero = null"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="capacidad"
              type="number"
              label="Capacidad"
              messages="Enter para buscar"
              clearable
              prepend-icon="mdi-account"
              @keydown.enter="onFetch"
              @click:clear="capacidad = null"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="volumen"
              type="number"
              label="Volumen"
              messages="Enter para buscar"
              clearable
              prepend-icon="mdi-account"
              @keydown.enter="onFetch"
              @click:clear="volumen = null"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <my-fabricante-select
              v-model="fabricante"
              clearable
            ></my-fabricante-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="presionDisenio"
              type="number"
              label="Presión Diseño"
              messages="Enter para buscar"
              clearable
              prepend-icon="mdi-counter"
              @keydown.enter="onFetch"
              @click:clear="presionDisenio = null"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="presionPh"
              type="number"
              label="Presión PH"
              messages="Enter para buscar"
              clearable
              prepend-icon="mdi-account"
              @keydown.enter="onFetch"
              @click:clear="presionPh = null"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <my-periodo-input
              label="Aprobado desde el"
              v-model="aprobadoFrom"
              clearable
            ></my-periodo-input>
          </v-col>
          <v-col cols="12" md="3">
            <my-periodo-input
              label="Aprobado hasta el"
              v-model="aprobadoTo"
              clearable
            ></my-periodo-input>
          </v-col>
          <v-col cols="12" md="3">
            <my-periodo-input
              label="Vencimiento desde el"
              v-model="vencimientoFrom"
              clearable
            ></my-periodo-input>
          </v-col>
          <v-col cols="12" md="3">
            <my-periodo-input
              label="Vencimiento hasta el"
              v-model="vencimientoTo"
              clearable
            ></my-periodo-input>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-divider
      v-if="showOrdering"
    ></v-divider>
    <!--
    <v-sheet
      v-if="showOrdering"
    >
      <v-toolbar flat>
        <v-btn
          icon
          @click.stop="showOrdering = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Ordenamiento</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-subheader>Filtros para recipientes</v-subheader>
        <v-chip @click="">PH vencidas</v-chip>
        <v-chip @click="">PH proximos a vencer</v-chip>
        <v-chip @click="">Libre de comodato</v-chip>
      </v-container>
    </v-sheet>
    -->
    <v-sheet
      v-if="showExports"
    >
      <v-toolbar flat>
        <v-btn
          icon
          @click.stop="showExports = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Exportaciones</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-chip @click="">Recipientes proximos a vencer</v-chip>
      </v-container>
    </v-sheet>
    <v-divider></v-divider>
    <v-navigation-drawer
      permanent
      app
    >
      <v-list
        nav
      >
        <v-list-item
          v-if="$can('certificados_fabricantes.store')"
          :to="{ name: 'fabricaciones-form' }"
          class="primary"
          dark
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f1</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cert. Fabricante</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-if="$can('certificados_rehabilitantes.store')"
          :to="{ name: 'rehabilitaciones-form' }"
          class="primary"
          dark
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f2</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cert. Rehabilitante</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          link
          :class="showFilters ? 'primary--text' : ''"
          @click.stop="showFilters = !showFilters">
          <v-list-item-icon>
            <v-icon :color="showOrdering ? 'primary' : ''">mdi-keyboard-f3</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Filtros</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!--
        <v-list-item
          link
          :class="showOrdering ? 'primary--text' : ''"
          @click.stop="showOrdering = !showOrdering">
          <v-list-item-icon>
            <v-icon :color="showOrdering ? 'primary' : ''">mdi-keyboard-f4</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Ordenamiento</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-sort-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        -->
        <v-list-item
          v-if="$can('recipientes.index')"
          @click="onFetch"
          :loading="loading"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f5</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Actualizar</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          link
          :class="showExports ? 'primary--text' : ''"
          @click.stop="showExports = !showExports"
        >
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>Exportaciones</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-export</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10,15,30,50,100]
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      :items-per-page="15"
      disable-sort
    >
      <template v-slot:item.id="{ item }">
        <div v-if="item.id">
          <v-icon @click="">mdi-text-box</v-icon>
        </div>
      </template>
      <template v-slot:item.fabricante="{ item }">
        <div v-if="item.fabricante">
          {{ item.fabricante.nombre }}
        </div>
      </template>
      <template v-slot:item.tipo="{ item }">
        <div v-if="item.tipo">
          {{ item.tipo.abreviatura }}
        </div>
      </template>
      <template v-slot:item.vencimiento_ph="{ item }">
        <div v-if="item.vencimiento_ph">
          {{ item.vencimiento_ph | moment('MM/YYYY') }}
        </div>
      </template>
      <template v-slot:item.aprobado_ph="{ item }">
        <div v-if="item.aprobado_ph">
          {{ item.aprobado_ph | moment('MM/YYYY') }}
        </div>
      </template>
      <template v-slot:item.fabricacion="{ item }">
        <v-chip
          v-show="!item.fabricacion"
        >
          Sin datos
        </v-chip>
        <v-chip
          :to="{ name: 'fabricaciones-form', params: { id: item.fabricacion.id } }"
          v-if="item.fabricacion"
        >
          {{ item.fabricacion.identificacion }}
        </v-chip>
      </template>
      <template v-slot:item.rehabilitacion="{ item }">
        <v-chip
          v-show="!item.rehabilitacion"
        >
          Sin datos
        </v-chip>
        <v-chip
          :to="{ name: 'rehabilitaciones-form', params: { id: item.rehabilitacion.id } }"
          v-if="item.rehabilitacion"
        >
          {{ item.rehabilitacion.identificacion }}
        </v-chip>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de recipientes para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import PeriodoInput from '@/components/PeriodoInput'
import FabricanteSelect from '@/pages/fabricantes/Select'
export default {
  components: {
    'my-periodo-input': PeriodoInput,
    'my-fabricante-select': FabricanteSelect,
  },
  data () {
    return {
      showFilters: false,
      showOrdering: false,
      showExports: false,
    }
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Recipientes', ['all', 'anySelectedFilters', 'headers', 'loading', 'itemsLength', 'filters', 'options', 'sort']),
    sortBy: {
      get () {
        return this.sort.by
      },
      set (value) {
        this.setSortBy(value)
        this.onFetch()
      }
    },
    sortDesc: {
      get () {
        return this.sort.desc
      },
      set (value) {
        this.setSortDesc(value)
        this.onFetch()
      }
    },
    numero: {
      get () {
        return this.filters.numero
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          numero: value
        })
      }
    },
    capacidad: {
      get () {
        return this.filters.capacidad
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          capacidad: value
        })
      }
    },
    volumen: {
      get () {
        return this.filters.volumen
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          volumen: value
        })
      }
    },
    aprobadoFrom: {
      get () {
        return this.filters.aprobado_from
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          aprobado_from: value
        })
        this.setPage(1)
        this.onFetch()
      }
    },
    aprobadoTo: {
      get () {
        return this.filters.aprobado_to
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          aprobado_to: value
        })
        this.setPage(1)
        this.onFetch()
      }
    },
    vencimientoFrom: {
      get () {
        return this.filters.vencimiento_from
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          vencimiento_from: value
        })
        this.setPage(1)
      }
    },
    vencimientoTo: {
      get () {
        return this.filters.vencimiento_to
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          vencimiento_to: value
        })
        this.setPage(1)
      }
    },
    presionDisenio: {
      get () {
        return this.filters.presion_disenio
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          presion_disenio: value
        })
      }
    },
    presionPh: {
      get () {
        return this.filters.presion_ph
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          presion_ph: value
        })
      }
    },
    fabricante: {
      get () {
        return this.filters.fabricante_id
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          fabricante_id: value
        })
        this.setPage(1)
        this.onFetch()
      }
    },
    certificadoIdentificacion: {
      get () {
        return this.filters.certificado_identificacion
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          certificado_identificacion: value
        })
      }
    },
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
  },
  methods: {
    ...mapActions('Recipientes', ['onClearFilters', 'onFetch']),
    ...mapMutations('Recipientes', ['setFilters', 'setOptions', 'setPage', 'setSortBy', 'setSortDesc']),
    onPhVencidas (event) {
      event.preventDefault()
      this.vencimientoFrom = null
      this.vencimientoTo = this.$moment().format('YYYY-MM-DD')
      this.onFetch()
    },
    onPhProximosVencimientos (event) {
      event.preventDefault()
      this.vencimientoFrom = this.$moment().format('YYYY-MM-DD')
      this.vencimientoTo = this.$moment().add(90, 'days').format('YYYY-MM-DD')
      this.onFetch()
    },
    onLibreComodato (event) {
      event.preventDefault()
    },
    onOrdenamientoVencimiento (value) {
      this.setSortBy('vencimiento_ph');
      this.setSortDesc(value);
      this.onFetch();
    },
    onOrdenamientoAprobado (value) {
      this.setSortBy('aprobado_ph');
      this.setSortDesc(value);
      this.onFetch();
    },
    onSubmitClearFilters (event) {
      event.preventDefault()
      this.onClearFilters()
      this.onFetch()
    },
    onInputIdentificacion (event) {
      if(event.key === "Enter") {
        event.preventDefault()
        this.onFetch()
      }
    },
    handleKeyboard(event) {
      if(event.key === "F1" && !event.ctrlKey) {
        event.preventDefault()
        // this.$router.push({ name: 'comodatos-form' })
      }
      else if(event.key === "F2" && !event.ctrlKey) {
        event.preventDefault()
        // this.$router.push({ name: 'clientes-form' })
      }
      else if(event.key === "F3" && !event.ctrlKey) {
        event.preventDefault()
        this.showFilters = !this.showFilters
      }
      else if(event.key === "F4" && !event.ctrlKey) {
        event.preventDefault()
        this.showOrdering = !this.showOrdering
      }
      else if(event.key === "F5" && !event.ctrlKey) {
        event.preventDefault()
        this.onFetch()
      }
      // event.preventDefault()
    }
  }
}
</script>
