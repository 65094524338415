<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-row no-gutters>
            <!--
            <v-col cols="12">
              <v-text-field
                :value="numero"
                prepend-icon="mdi-identifier"
                label="Identificación"
                color="primary"
              ></v-text-field>
            </v-col>
            -->
            <v-col cols="12">
              <v-list-item
                link
                @click="panelCliente = !panelCliente"
                :class="panelCliente ? 'primary--text' : ''"
              >
                <v-list-item-icon>
                  <v-icon :color="panelCliente ? 'primary' : ''">
                    {{ cliente ? 'mdi-account-star' : 'mdi-account-plus' }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle v-if="cliente">Cliente</v-list-item-subtitle>
                  <v-list-item-title v-else>Cliente</v-list-item-title>
                  <v-list-item-title v-if="cliente" class="text-wrap">{{ cliente }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                @click="panelDomicilio = !panelDomicilio"
                :class="panelDomicilio ? 'primary--text' : ''"
              >
                <v-list-item-icon>
                  <v-icon :color="panelDomicilio ? 'primary' : ''">
                    {{ panelDomicilio ? 'mdi-map-marker-star' : 'mdi-map-marker' }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle v-if="domicilio">Domicilio</v-list-item-subtitle>
                  <v-list-item-title v-else>Domicilio</v-list-item-title>
                  <v-list-item-title v-if="domicilio" class="text-wrap">{{ domicilio }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12">
              <my-date-picker
                label="Fecha firmado"
                v-model="firmado"
                clearable
              ></my-date-picker>
            </v-col>
            <v-col cols="12">
              <my-date-picker
                label="Fecha vencimiento"
                v-model="vencimiento"
                clearable
              ></my-date-picker>
            </v-col>
            
            <v-col cols="12">
              <v-list-item
                link
                @click="panelInstalacion = !panelInstalacion"
                :class="panelInstalacion ? 'primary--text' : ''"
              >
                <v-list-item-icon>
                  <v-icon :color="panelInstalacion ? 'primary' : ''">mdi-tools</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Instalación</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="panelRecipientes = !panelRecipientes"
                :class="panelRecipientes ? 'primary--text' : ''"
              >
                <v-list-item-icon>
                  <v-icon :color="panelRecipientes ? 'primary' : ''">mdi-propane-tank</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Recipientes instalados</v-list-item-title>
              </v-list-item>
            </v-col>
          </v-row>
          <v-footer>
            <v-btn
              :disabled="!updatable"
              @click="onSubmit"
              block
              color="primary"
            >
              Actualizar
            </v-btn>
          </v-footer>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DateInput from '@/components/DateInput'
export default {
  components: {
    'my-date-picker': DateInput,
  },
  data () {
    return {
      valid: false,
    }
  },
  created () {
    // this.onClearEdited()
    this.setPaneles({
      ...this.paneles,
      cliente: true,
      domicilio: true
    })
  },
  computed: {
    ...mapGetters('Comodatos',['edited', 'paneles', 'rules', 'loading', 'updatable']),
    ...mapGetters('Clientes',{ selectedCliente: 'selected' }),
    ...mapGetters('Domicilios',{ selectedDomicilio: 'selected' }),
    cliente () {
      return this.edited.cliente_id && this.selectedCliente ? this.selectedCliente.razon_social : null
    },
    domicilio () {
      return this.edited.domicilio_id && this.selectedDomicilio ? this.selectedDomicilio.descripcion : null
    },
    id () {
      return this.edited.id
    },
    panelCliente: {
      get () {
        return this.paneles.cliente
      },
      set (value) {
        this.setPaneles({
          ...this.paneles,
          cliente: value
        })
      }
    },
    panelDomicilio: {
      get () {
        return this.paneles.domicilio
      },
      set (value) {
        this.setPaneles({
          ...this.paneles,
          domicilio: value
        })
      }
    },
    panelInstalacion: {
      get () {
        return this.paneles.instalacion
      },
      set (value) {
        this.setPaneles({
          ...this.paneles,
          instalacion: value
        })
      }
    },
    panelRecipientes: {
      get () {
        return this.paneles.recipientes
      },
      set (value) {
        this.setPaneles({
          ...this.paneles,
          recipientes: value
        })
      }
    },
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          numero: value
        })
      }
    },
    firmado: {
      get () {
        return this.edited.firmado
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          firmado: value
        })
      }
    },
    vencimiento: {
      get () {
        return this.edited.vencimiento
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          vencimiento: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Comodatos', ['onUpdate', 'onClearEdited']),
    ...mapMutations('Comodatos', ['setEdited', 'setPaneles']),
    onSubmit (event) {
      event.preventDefault()
      this.onUpdate().then(response => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
