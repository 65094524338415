<template>
  <div>
    <v-sheet
      v-if="showFilters"
    >
      <v-toolbar flat>
        <v-toolbar-title>Filtros</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="onClearFilters"
          :disabled="anySelectedFilters"
          :loading="loading"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp" dark>mdi-filter-variant-remove</v-icon>
          <div class="hidden-xs-only">Limpiar filtros</div>
        </v-btn>
      </v-toolbar>
      <v-container>
      
        <v-chip @click="">Comodatos próximos a vencer (90 dias)</v-chip>
        <v-chip @click="">Comodatos vencidos</v-chip>
        <v-chip @click="">Comodatos incompletos</v-chip>
        <v-row no-gutters>
          <!--
          <v-col cols="12" md="3">
            <my-date-picker
              label="Firmado desde el"
              v-model="firmadoFrom"
              clearable
            ></my-date-picker>
            <my-date-picker
              label="Firmado hasta el"
              v-model="firmadoTo"
              clearable
            ></my-date-picker>
          </v-col>
          <v-col cols="12" md="3">
            <my-date-picker
              label="Vencimiento desde el"
              v-model="vencimientoFrom"
              clearable
            ></my-date-picker>
            <my-date-picker
              label="Vencimiento hasta el"
              v-model="vencimientoTo"
              clearable
            ></my-date-picker>
          </v-col>
          <v-col cols="12" md="3">
            <my-zona-select
              v-model="zona"
              clearable
            ></my-zona-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="cliente"
              label="Cliente"
              messages="Enter para buscar"
              clearable
              prepend-icon="mdi-account"
              @keydown.enter="onFetch"
              @click:clear="onClear"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="domicilio"
              label="Domicilio"
              messages="Enter para buscar"
              clearable
              prepend-icon="mdi-map-marker"
              @keydown.enter="onFetch"
              @click:clear="onClear"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <my-tipo-instalacion-select
              v-model="tipoInstalacion"
              clearable
            ></my-tipo-instalacion-select>
          </v-col>
          -->
        </v-row>
      </v-container>
    </v-sheet>
    <v-divider
      v-if="showOrdering"
    ></v-divider>
    <v-sheet
      v-if="showOrdering"
    >
      <v-toolbar flat>
        <v-toolbar-title>Ordenamiento</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <!--
            <v-chip-group column active-class="primary">
              <v-chip @click.stop="onOrdenamientoVencimiento(1)">Publicados recientemente</v-chip>
              <v-chip @click.stop="onOrdenamientoActualizados(1)">Actualizados recientemente</v-chip>
              <v-chip @click.stop="onOrdenamientoCreados(1)">Creados recientemente</v-chip>
              <v-chip @click.stop="onOrdenamientoVencimiento(0)">Primeros publicados</v-chip>
              <v-chip @click.stop="onOrdenamientoActualizados(0)">Primeras actualizaciones</v-chip>
              <v-chip @click.stop="onOrdenamientoCreados(0)">Primeros creados</v-chip>
            </v-chip-group>
            -->
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-divider></v-divider>
    <v-navigation-drawer
      permanent
      app
    >
      <v-list
        nav
      >
        <v-list-item
          v-if="$can('clientes.store')"
          :to="{ name: 'comodatos-form' }"
          class="primary"
          dark
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f1</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nuevo comodato</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-if="$can('clientes.store')"
          :to="{ name: 'clientes-form' }"
          class="primary"
          dark
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f2</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nuevo cliente</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          link
          :class="showFilters ? 'primary--text' : ''"
          @click.stop="showFilters = !showFilters"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f3</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Filtros</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          link
          :class="showOrdering ? 'primary--text' : ''"
          @click.stop="showOrdering = !showOrdering">
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f4</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Ordenamiento</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-sort-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-if="$can('clientes.index')"
          @click="onFetch"
          :loading="loading"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f5</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Actualizar</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          link
          :class="showExports ? 'primary--text' : ''"
          @click.stop="showExports = !showExports"
        >
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>Exportaciones</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-export</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    
    <v-sheet>
      <v-container>
        <v-text-field
          v-model="searchText"
          prepend-icon="mdi-card-account-details"
          color="primary"
          placeholder="Escriba..."
          clearable
          @keydown.enter="onFetch"
          @click:clear="onClear"
        >
          <template v-slot:prepend>
            <v-btn
              icon
              color="primary"
              @click="onFetch"
              :disabled="!searchText || searchText.length == 0"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-container>
    </v-sheet>
    <v-sheet
      v-if="showExports"
    >
      <v-toolbar flat>
        <v-btn
          icon
          @click.stop="showExports = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Exportaciones</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-chip @click="">Recipientes proximos a vencer</v-chip>
      </v-container>
    </v-sheet>
    <v-data-table
      :headers="headers"
      :items="all"
      :search="search"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
      disable-sort
    >
      <template v-slot:top>
        <v-subheader>Datos de clientes</v-subheader>
      <!--
        <v-toolbar flat>
          <v-text-field
            v-model="searchText"
            prepend-icon="mdi-card-account-details"
            color="primary"
            placeholder="Escriba..."
            clearable
            @keydown.enter="onFetch"
            @click:clear="onClear"
          >
            <template v-slot:prepend>
              <v-btn
                icon
                color="primary"
                @click="onFetch"
                :disabled="!searchText || searchText.length == 0"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-toolbar>
        -->
      </template>
      <template v-slot:item.id="{ item }">
        <v-btn
          v-if="$can('clientes.update')"
          icon
          @click="onSubmitEdit(item.id)"
        >
          <v-icon>mdi-pencil-circle</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.inactivo="{ item }">
        <div>0</div>
      </template>
      <template v-slot:item.razon_social="{ item }">
        <div v-if="item.razon_social">
          {{ item.razon_social }}
        </div>
      </template>
      <template v-slot:no-results>
        <v-alert color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert color="warning" icon="mdi-alert">
          No hay información de clientes para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TipoInstalacionSelect from '@/pages/tipos_instalaciones/Select'
import DateInput from '@/components/DateInput'
import ZonaSelect from '@/pages/zonas/Select'
export default {
  components: {
    'my-tipo-instalacion-select': TipoInstalacionSelect,
    'my-date-picker': DateInput,
    'my-zona-select': ZonaSelect,
  },
  data () {
    return {
      search: '',
      selected: [],
      showFilters: false,
      showOrdering: false,
      showExports: false,
    }
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // this.onFetch()
  },
  computed: {
    ...mapGetters('Clientes', ['all', 'headers', 'loading', 'itemsLength', 'options', 'filters']),
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
    anySelectedFilters (value) {
      const obj = this.filters
      // Itera sobre las claves del objeto
      for (const key in obj) {
        // Verifica si el valor es distinto de null e undefined
        if (obj[key] !== null && obj[key] !== undefined) {
          // Si encuentra un valor que no es null ni undefined, retorna false
          return false
        }
      }
      // Si todos los valores son null o undefined, retorna true
      return true
    }
  },
  methods: {
    ...mapActions('Clientes', ['onFetch', 'onClearFilters']),
    ...mapMutations('Clientes', ['setFilters', 'setOptions', 'setEdited', 'setSelected']),
    onClear () {
      this.setFilters({
        ...this.filters,
        searchText: null
      })
      this.onFetch()
    },
    onSubmitEdit (clienteId) {
      this.$router.push({ name: 'clientes-form', params: { id: clienteId }})
    },
    handleKeyboard(event) {
      if(event.key === "F1" && !event.ctrlKey) {
        event.preventDefault()
        this.$router.push({ name: 'comodatos-form' })
      }
      else if(event.key === "F2" && !event.ctrlKey) {
        event.preventDefault()
        this.$router.push({ name: 'clientes-form' })
      }
      else if(event.key === "F3" && !event.ctrlKey) {
        event.preventDefault()
        this.showFilters = !this.showFilters
      }
      else if(event.key === "F4" && !event.ctrlKey) {
        event.preventDefault()
        this.showOrdering = !this.showOrdering
      }
      else if(event.key === "F5" && !event.ctrlKey) {
        event.preventDefault()
        this.onFetch()
      }
      // event.preventDefault()
    }
  }
}
</script>
