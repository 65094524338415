const URL = '/tipos_instalaciones';

const state = {
  all: [],
  loading: false
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  loading (state) {
    return state.loading
  }
}

// ACTIONS
const actions = {
  onFetch ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL)
        .then(response => {
          commit('setAll', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  }
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setLoading (state, payload) {
    state.loading = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
