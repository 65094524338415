<template>
  <div>
    <v-select
      v-model="selectedLocal"
      :items="all"
      hide-no-data
      item-text="nombre"
      item-value="id"
      :rules="rules"
      prepend-icon="mdi-propane-tank"
      :label="label"
      :placeholder="placeholder"
      :color="color"
      :readonly="readonly"
      :clearable="clearable"
    >
    <!--
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item
          v-bind="attrs"
          v-on="on"
          dense
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.nombre }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    -->
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Number,
    rules: Array,
    readonly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
  },
  data () {
    return {
      placeholder: 'Seleccione un tipo',
      label: 'Tipo'
    }
  },
  created () {
    this.onFetch()
  },
  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },

      set (value) {
        this.$emit('change', value)
      }
    },
    ...mapGetters('RecipientesTipos', ['all']),
  },
  methods: {
    ...mapActions('RecipientesTipos', ['onFetch']),
    onChange (value) {
      // this.selectedLocal = this.all.value
    }
  }
}
</script>
