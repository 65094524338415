var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.all,"footer-props":{
      itemsPerPageText: 'Filas por página',
      itemsPerPageOptions: [10,30,100]
    },"loading":_vm.loading,"options":_vm.optionsLocal,"server-items-length":_vm.itemsLength,"items-per-page":10,"disable-sort":""},on:{"update:options":function($event){_vm.optionsLocal=$event},"click:row":_vm.onSelect},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
    var item = ref.item;
return [(item.id)?_c('div',[_vm._t("acciones",null,{"item":item})],2):_vm._e()]}},{key:"item.fabricante",fn:function(ref){
    var item = ref.item;
return [(item.fabricante)?_c('div',[_vm._v("\n        "+_vm._s(item.fabricante.nombre)+"\n      ")]):_vm._e()]}},{key:"item.tipo",fn:function(ref){
    var item = ref.item;
return [(item.tipo)?_c('div',[_vm._v("\n        "+_vm._s(item.tipo.abreviatura)+"\n      ")]):_vm._e()]}},{key:"item.vencimiento_ph",fn:function(ref){
    var item = ref.item;
return [(item.vencimiento_ph)?_c('div',[_vm._v("\n        "+_vm._s(_vm._f("moment")(item.vencimiento_ph,'MM/YYYY'))+"\n      ")]):_vm._e()]}},{key:"item.aprobado_ph",fn:function(ref){
    var item = ref.item;
return [(item.aprobado_ph)?_c('div',[_vm._v("\n        "+_vm._s(_vm._f("moment")(item.aprobado_ph,'MM/YYYY'))+"\n      ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"warning","icon":"mdi-alert"}},[_vm._v("\n        No hay información de recipientes para mostrar.\n      ")])]},proxy:true},{key:"pageText",fn:function(props){return [_vm._v("\n      Filas "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+"\n    ")]}}],null,true)}),_vm._v(" "),_c('my-recipiente-edit',{on:{"updated":_vm.onUpdated,"deleted":_vm.onDeleted},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }