<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-row no-gutters>
            <!--
            <v-col cols="12">
              <v-text-field
                :value="numero"
                prepend-icon="mdi-identifier"
                label="Identificación"
                color="primary"
              ></v-text-field>
            </v-col>
            -->
            <v-col cols="12">
              <v-list-item
                link
              >
                <v-list-item-icon>
                  <v-icon>
                    {{ clienteId ? 'mdi-account-star' : 'mdi-account-plus' }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle v-if="clienteId">Cliente</v-list-item-subtitle>
                  <v-list-item-title v-else>Cliente</v-list-item-title>
                  <v-list-item-title v-if="clienteId" class="text-wrap">{{ cliente }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
              >
                <v-list-item-icon>
                  <v-icon v-if="domicilio">mdi-map-marker-star</v-icon>
                  <v-icon v-else>mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle v-if="domicilioId">Domicilio</v-list-item-subtitle>
                  <v-list-item-title v-else>Domicilio</v-list-item-title>
                  <v-list-item-title v-if="domicilioId" class="text-wrap">{{ domicilio }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12">
              <my-date-picker
                label="Fecha firmado"
                v-model="firmado"
                clearable
              ></my-date-picker>
            </v-col>
            <v-col cols="12">
              <my-date-picker
                label="Fecha vencimiento"
                v-model="vencimiento"
                clearable
              ></my-date-picker>
            </v-col>
          </v-row>
          <v-footer>
            <v-btn
              @click="onSaveSubmit"
              block
              color="primary"
            >
              Registrar
            </v-btn>
          </v-footer>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DateInput from '@/components/DateInput'
export default {
  components: {
    'my-date-picker': DateInput,
  },
  data () {
    return {
      valid: false,
    }
  },
  created () {
    // this.$store.dispatch('Comodatos/onClearEdited')
  },
  computed: {
    cliente () {
      return this.edited.cliente_id && this.selectedCliente ? this.selectedCliente.razon_social : null
    },
    domicilio () {
      return this.edited.cliente_id && this.selectedDomicilio ? this.selectedDomicilio.descripcion : null
    },
    id () {
      return this.edited.id
    },
    loading () {
      return this.$store.getters['Comodatos/loading']
    },
    rules () {
      return this.$store.getters['Comodatos/rules']
    },
    selectedCliente () {
      return this.$store.getters['Clientes/selected']
    },
    selectedDomicilio () {
      return this.$store.getters['Domicilios/selected']
    },
    paneles: {
      get () {
        return this.$store.getters['Comodatos/paneles']
      },
      set (value) {
        this.$store.commit('Comodatos/setPaneles', value)
      }
    },
    panelCliente: {
      get () {
        return this.paneles.cliente
      },
      set (value) {
        this.paneles = {
          ...this.paneles,
          cliente: value
        }
      }
    },
    panelDomicilio: {
      get () {
        return this.paneles.domicilio
      },
      set (value) {
        this.paneles = {
          ...this.paneles,
          domicilio: value
        }
      }
    },
    edited: {
      get () {
        return this.$store.getters['Comodatos/edited']
      },
      set (value) {
        this.$store.commit('Comodatos/setEdited', value)
      }
    },
    clienteId () {
      return this.edited.cliente_id
    },
    domicilioId () {
      return this.edited.domicilio_id
    },
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.edited = {
          ...this.edited,
          numero: value
        }
      }
    },
    firmado: {
      get () {
        return this.edited.firmado
      },
      set (value) {
        this.edited = {
          ...this.edited,
          firmado: value
        }
      }
    },
    vencimiento: {
      get () {
        return this.edited.vencimiento
      },
      set (value) {
        this.edited = {
          ...this.edited,
          vencimiento: value
        }
      }
    },
  },
  methods: {
    onSave () {
      return this.$store.dispatch('Comodatos/onSave')
    },
    onSaveSubmit (event) {
      event.preventDefault()
      this.onSave().then(response => {
        /* se recarga la pagina para completar la URL con el id */
        /* de esta manera se evita que se recargue la pagina y se pierdan los datos */
        this.$router.push({ name: 'comodatos-form', params: { id: this.edited.id } })
      })
    },
  },
}
</script>
