<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-sheet>
          <v-subheader>Datos del comodato</v-subheader>
          <my-comodato-edit
            v-if="isSelected"
          ></my-comodato-edit>
          <my-comodato-create
            v-else
          ></my-comodato-create>
        </v-sheet>
      </v-col>
      <v-col cols="8">
        <my-cliente-form
          v-show="paneles.cliente"
        ></my-cliente-form>
        <!--  v-show="paneles.cliente" -->
        <v-divider></v-divider>
        <my-domicilio-form
          v-show="paneles.domicilio"
        ></my-domicilio-form>
        <!--  v-show="paneles.domicilio" -->
        <v-divider></v-divider>
        <!--
        <my-vigencia-form
          v-show="paneles.vigencia"
        ></my-vigencia-form>
        -->
        <v-divider></v-divider>
        <my-instalacion-form
          v-show="paneles.instalacion"
        ></my-instalacion-form>
        <v-divider></v-divider>
        <my-comodatos-tanques-form
          v-show="paneles.recipientes"
        ></my-comodatos-tanques-form>
        <my-recipientes-form
          v-show="paneles.recipientes"
        ></my-recipientes-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ComodatoCreate from '@/pages/comodatos/Create'
import ComodatoEdit from '@/pages/comodatos/Edit'
// import ComodatoEdit from '@/pages/comodatos/Create'
import ClienteForm from '@/pages/comodatos/ClienteForm'
import DomicilioForm from '@/pages/comodatos/DomicilioForm'
import VigenciaForm from '@/pages/comodatos/VigenciaForm'
import InstalacionForm from '@/pages/comodatos_instalaciones/Form'
import RecipientesForm from '@/pages/comodatos_recipientes/Form'
import TanquesForm from '@/pages/comodatos_tanques/Form'
export default {
  components: {
    'my-cliente-form': ClienteForm,
    'my-domicilio-form': DomicilioForm,
    'my-instalacion-form': InstalacionForm,
    'my-vigencia-form': VigenciaForm,
    'my-recipientes-form': RecipientesForm,
    'my-comodato-edit': ComodatoEdit,
    'my-comodato-create': ComodatoCreate,
    'my-comodatos-tanques-form': TanquesForm
  },
  data () {
    return {
      valid: {
        cliente: true,
        domicilio: true,
        instalacion: true,
        recipientes: true,
      },
      panel: {
        showCliente: true,
        showDomicilio: true,
        showVigencia: true,
        showInstalacion: true,
        showRecipientes: true
      },
      faq: false,
      stepActual: 1,
      tipoInstalacion: null,
      orden: null,
      fechaInstalacion: null,
      comodatoId: null,
    }
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
    this.$store.dispatch('Clientes/onClearEdited');
    this.$store.dispatch('Clientes/onClearSelected');

    this.$store.dispatch('Comodatos/onClearEdited');
    this.$store.dispatch('Comodatos/onClearSelected');

    this.$store.dispatch('Domicilios/onClearEdited');
    this.$store.dispatch('Domicilios/onClearSelected');
  },
  created () {
    // this.$store.dispatch('Clientes/onClearEdited');
    // this.$store.dispatch('Clientes/onClearSelected');
    this.comodatoId = parseInt(this.$route.params.id)
    if (this.comodatoId) {
      this.onFind()
        .then(response => {
          this.$store.dispatch('Domicilios/onFind', this.edited.domicilio_id)
          this.$store.dispatch('Clientes/onFind', this.edited.cliente_id)

          const filtersInstalaciones = {
            ...this.$store.getters['ComodatosInstalaciones/filters'],
            comodato_id: this.edited.id
          }
          const filtersRecipientes = {
            ...this.$store.getters['ComodatosRecipientes/filters'],
            comodato_id: this.edited.id
          }
          const filtersTanques = {
            ...this.$store.getters['ComodatosTanques/filters'],
            comodato_id: this.edited.id
          }

          this.$store.commit('ComodatosInstalaciones/setFilters', filtersInstalaciones)
          this.$store.commit('ComodatosRecipientes/setFilters', filtersRecipientes)
          this.$store.commit('ComodatosTanques/setFilters', filtersRecipientes)
        })
    }
    else {
      this.setPaneles({
        ...this.paneles,
        cliente: true,
        domicilio: true
      })
    }
  },
  computed: {
    edited: {
      get () {
        return this.$store.getters['Comodatos/edited']
      },
      set (value) {
        this.$store.commit('Comodatos/setEdited', value)
      }
    },
    paneles: {
      get () {
        return this.$store.getters['Comodatos/paneles']
      },
      set (value) {
        this.$store.commit('Comodatos/setPaneles', value)
      }
    },
    cliente () {
      const clienteEdited = this.$store.getters['Clientes/edited']
      return this.clienteEdited.razon_social || this.clienteEdited.documento || null
    },
    domicilio () {
      const domicilioEdited = this.$store.getters['Domicilios/edited']
      return domicilioEdited.descripcion || this.domicilioEdited.observaciones || null
    },
    isSelected () {
      return this.edited && this.edited.id
    },
  },
  methods: {
    onFind () {
      return this.$store.dispatch('Comodatos/onFind', this.comodatoId)
    },
    ...mapMutations('Comodatos', ['setPaneles']),
    handleKeyboard(event) {
      if(event.key === "F1") {
        event.preventDefault()
        this.$router.push({ name: 'comodatos-datatable' })
      }
      // else if(event.key === "F3") {
      //   event.preventDefault()
      // }
      // else if(event.key === "F4") {
      //   event.preventDefault()
      // }
      // else if(event.key === "F5") {
      //   event.preventDefault()
      // }
      // event.preventDefault()
    }
  },
}
</script>
