<template>
  <div>
    <v-navigation-drawer
      v-model="drawerLocal"
      right
      app
      temporary
      width="350px"
    >
      <v-toolbar flat dense>
        <v-btn
          @click="onClickClose"
          icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-subheader>Caracteristicas del fabricante</v-subheader>
      </v-toolbar>
      <my-fabricante-create
        v-show="!isSelected"
        @success="onEventSuccess"
      ></my-fabricante-create>
      <my-fabricante-edit
        v-show="isSelected"
        @success="onEventSuccess"
      >
      </my-fabricante-edit>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import FabricanteCreate from '@/pages/fabricantes/Create'
import FabricanteEdit from '@/pages/fabricantes/Edit'
export default {
  components: {
    'my-fabricante-create': FabricanteCreate,
    'my-fabricante-edit': FabricanteEdit
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
    }
  },
  created () {
    this.onClearEdited()
    this.onClearSelected()
  },
  computed: {
    ...mapGetters('Fabricantes', ['edited', 'loading']),
    isSelected () {
      return this.edited.id && this.edited.id > 0
    },
    drawerLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
  },
  watch: {
    //
  },
  methods: {
    ...mapActions('Fabricantes', ['onFetch', 'onClearEdited', 'onClearSelected']),
    onEventSuccess () {
      this.onFetch()
      this.drawerLocal = false
    },
    onClickClose (event) {
      event.preventDefault()
      this.drawerLocal = false
    }
  },
}
</script>