const URL = '/recipientes';

const state = {
  all: [],
  filters: {
    certificado_fabricante_id: null,
  },
  loading: false,
  headers: [
    {
      text: 'Numero',
      value: 'numero',
      align: 'center'
    },
    {
      text: 'Fabricante',
      value: 'fabricante',
      align: 'center'
    },
    {
      text: 'Tipo',
      value: 'tipo',
      align: 'center'
    },
    {
      text: 'Capacidad (m3)',
      value: 'capacidad',
      align: 'center'
    },
    {
      text: 'Volumen (Lts)',
      value: 'volumen',
      align: 'center'
    },
    {
      text: 'Presión diseño (Kg/cm2)',
      value: 'presion_disenio',
      align: 'center'
    },
    {
      text: 'Presión PH (Kg/cm2)',
      value: 'presion_ph',
      align: 'center'
    },
    {
      text: 'Fecha PH',
      value: 'aprobado_ph',
      align: 'center'
    },
    {
      text: 'Vencimiento PH',
      value: 'vencimiento_ph',
      align: 'center'
    },
  ],
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  filters (state) {
    return state.filters
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
          params: {
            itemsPerPage: state.options.itemsPerPage,
            page: state.options.page,
            // numero: state.filters.numero,
            // capacidad: state.filters.capacidad,
            // volumen: state.filters.volumen,
            // aprobado_from: state.filters.aprobado.from,
            // aprobado_to: state.filters.aprobado.to,
            // vencimiento_from: state.filters.vencimiento.from,
            // vencimiento_to: state.filters.vencimiento.to,
            // presion_disenio: state.filters.presion_disenio,
            // presion_ph: state.filters.presion_ph,
            certificado_fabricante_id: state.filters.certificado_fabricante_id,
            // fabricante_id: state.filters.fabricante_id,
            sortBy: state.options.sortBy[0] || '',
            sortDesc: state.options.sortDesc[0] ? 1 : 0,
          }
        })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          commit('setAll', [])
          commit('setItemsLength', 0)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
