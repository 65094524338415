<template>
  <div>
    <v-row>
      <v-col cols="4">
        <my-fabricaciones-edit
          v-if="isSelected"
        ></my-fabricaciones-edit>
        <my-fabricaciones-create
          v-else
        ></my-fabricaciones-create>
      </v-col>
      <v-col cols="8">
        <v-sheet>
          <v-container>
            <v-subheader>Recipientes asociados</v-subheader>
            <div v-show="isSelected">
              <my-recipientes-datatable
                :update-collection="recipienteNuevo"
              ></my-recipientes-datatable>
            </div>
            <div v-show="!isSelected">
              <v-skeleton-loader
                v-bind="attrs"
                type="table"
              ></v-skeleton-loader>
            </div>
          </v-container>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet v-show="isSelected">
          <my-recipientes-create
            @success="onUpdateRecipientes"
            :certificado-fabricante-id="certificadoFabricanteId"
            :reset-form="recipienteNuevo"
          ></my-recipientes-create>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DateInput from '@/components/DateInput'
import RecipientesCreate from '@/pages/recipientes/Create'
import FabricanteSelect from '@/pages/fabricantes/Select'
import FabricacionesCreate from '@/pages/fabricaciones/Create'
import FabricacionesEdit from '@/pages/fabricaciones/Edit'
import RecipientesAsociados from '@/pages/fabricaciones_recipientes/Datatable'
export default {
  components: {
    'my-date-picker': DateInput,
    'my-fabricante-select': FabricanteSelect,
    'my-recipientes-datatable': RecipientesAsociados,
    'my-fabricaciones-create': FabricacionesCreate,
    'my-fabricaciones-edit': FabricacionesEdit,
    'my-recipientes-create': RecipientesCreate,
  },
  data () {
    return {
      valid: true,
      attrs: {
        boilerplate: true,
      },
      recipienteNuevo: false,
      panelOpen: [1], // Inicialmente cerrado
    }
  },
  created () {
    this.onClearEdited()
    this.onClearSelected()
    if (this.$route.params.id) {
      this.onFind(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters('Fabricaciones', ['edited', 'rules', 'loading']),
    isSelected () {
      return this.edited.id > 0
    },
    certificadoFabricanteId () {
      return this.edited.id
    },
  },
  watch: {
    certificadoFabricanteId (value) {
      // alert(value)
    }
  },
  methods: {
    ...mapActions('Fabricaciones', ['onFind', 'onClearEdited', 'onClearSelected']),
    onUpdateRecipientes () {
      this.recipienteNuevo = true
      // Luego de completar la lógica, cierra el panel
      this.panelOpen = undefined;
    }
  },
}
</script>
