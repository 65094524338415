var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{model:{value:(_vm.panelOpen),callback:function ($$v) {_vm.panelOpen=$$v},expression:"panelOpen"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"searchPanel"}},[_vm._v("Recipientes disponibles para agregar al certificado")]),_vm._v(" "),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.all,"footer-props":{
            itemsPerPageText: 'Filas por página',
            itemsPerPageOptions: [5, 15]
          },"loading":_vm.loading,"options":_vm.optionsLocal,"server-items-length":_vm.itemsLength,"items-per-page":5,"disable-sort":""},on:{"update:options":function($event){_vm.optionsLocal=$event},"click:row":_vm.onSelect},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("top"),_vm._v(" "),_c('v-text-field',{attrs:{"placeholder":"Escriba algún dato del recipiente","hint":"Enter para buscar recipiente"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onSubmit}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})]},proxy:true},{key:"item.fabricante",fn:function(ref){
          var item = ref.item;
return [(item.fabricante)?_c('div',[_vm._v("\n              "+_vm._s(item.fabricante.nombre)+"\n            ")]):_vm._e()]}},{key:"item.tipo",fn:function(ref){
          var item = ref.item;
return [(item.tipo)?_c('div',[_vm._v("\n              "+_vm._s(item.tipo.abreviatura)+"\n            ")]):_vm._e()]}},{key:"item.vencimiento_ph",fn:function(ref){
          var item = ref.item;
return [(item.vencimiento_ph)?_c('div',[_vm._v("\n              "+_vm._s(_vm._f("moment")(item.vencimiento_ph,'MM/YYYY'))+"\n            ")]):_vm._e()]}},{key:"item.aprobado_ph",fn:function(ref){
          var item = ref.item;
return [(item.aprobado_ph)?_c('div',[_vm._v("\n              "+_vm._s(_vm._f("moment")(item.aprobado_ph,'MM/YYYY'))+"\n            ")]):_vm._e()]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"color":"error","icon":"mdi-alert"}},[_vm._v("\n              No se ha encontrado ningún resultado para \""+_vm._s(_vm.search)+"\".\n            ")])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"color":"warning","icon":"mdi-alert"}},[_vm._v("\n              No hay información de recipientes para mostrar.\n            ")])]},proxy:true},{key:"pageText",fn:function(props){return [_vm._v("\n            Filas "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+"\n          ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }