<template>
  <div>
    <v-sheet>
      <v-container fluid>
        <v-toolbar flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="error"
            @click="onSubmit"
            :loading="loading"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-delete</v-icon>
            <div class="hidden-xs-only">{{ submitButtonText }}</div>
          </v-btn>
        </v-toolbar>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="descripcion"
              placeholder="Descripcion"
              prepend-icon="mdi-text"
              label="Descripcion"
              v-bind="attrs"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="latitud"
              placeholder="Ingrese una latitud"
              prepend-icon="mdi-text"
              label="Latitud"
              v-bind="attrs"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="longitud"
              placeholder="Ingrese una longitud"
              prepend-icon="mdi-text"
              label="Longitud"
              v-bind="attrs"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="observaciones"
              placeholder="Observaciones"
              prepend-icon="mdi-text"
              label="Observaciones del domicilio"
              v-bind="attrs"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  components: {
    //
  },
  data () {
    return {
      redirect: 'domicilios-list',
      cliente_id: null,
      title: '¿Está seguro de borrar este registro?',
      submitButtonText: 'Borrar',
      cancelButtonText: 'Cancelar',
      attrs: {
        color: 'error',
        readonly: true,
      },
    }
  },
  created () {
    this.onDefaults()
    if (this.$route.params.id)
      this.onFind(this.$route.params.id).then(data => {
        this.cliente_id = data.cliente_id
      })
  },
  computed: {
    ...mapGetters({
      edited: 'Domicilios/edited',
      loading: 'Domicilios/loading',
    }),
    descripcion () {
      return this.edited.descripcion
    },
    latitud () {
      return this.edited.latitud
    },
    longitud () {
      return this.edited.longitud
    },
    observaciones () {
      return this.edited.observaciones
    },
    zona_id () {
      return this.edited.zona_id
    },
  },
  watch: {
    //
  },
  methods: {
    ...mapActions({
      onDelete: 'Domicilios/onDelete',
      onFind: 'Domicilios/onFind',
      onDefaults: 'Domicilios/onClearEdited',
    }),
    onCancel () {
      this.$router.push({ name: this.redirect, params: { id: this.cliente_id }})
    },
    onSubmit () {
      this.onDelete().then(response => {
        this.$router.push({ name: this.redirect, params: { id: this.cliente_id }})
      })
    },
  },
}
</script>
